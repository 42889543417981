/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
import { FiLock, FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
// PARTIALS
import ForgotPasswordBlock from './_partials/ForgotPasswordBlock';
// ASSETS
// import Logo from '../../../assets/img/logo.svg';
import Logo from '../../../assets/img/logo_gradient.svg';
// SERVICES & HELPERS
import LoginValidator from '../../../helpers/validators/user/LoginValidator';
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';
// REDUX
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

const initialModel = {
  email: '',
  password: ''
};

const LoginPage = (props) => {
  const { showAlert, login, history } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forgotPasswordBlockVisible, setForgotPasswordBlockVisible] = useState(false);

  const iconSize = 22;

  const loginUser = (model, setSubmitting) => {
    userService.login(model).then((res) => {
      login(res);
      history.push('/admin/management/users');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const formik = useFormik({
    initialValues: initialModel,
    validationSchema: LoginValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      loginUser(values, setSubmitting);
    },
  });

  return (
  // <CustomBlock className="d-flex flex-wrap w-100 h-100">
  //   {/* MAIN FLEX CONTAINER FOR 2 COLUMNS */}
  // </CustomBlock>
    <CustomBlock className="login-page d-flex flex-row w-100 h-100">
    
      {/* LOGO SECTION */}
      <CustomBlock className="d-flex flex-column justify-content-center align-items-center flex-grow-1 gap-3" style={{ maxWidth: '40%' }}>
        <img src={Logo} className="w-75" style={{ maxWidth: '250px' }} alt="" />
        <p className="size-xxxs dark--clr fw-400 text-center">
          {`Copyright © ${new Date().getFullYear()} Geaux Engage. All rights reserved.`}
        </p>
      </CustomBlock>

      {/* LOGIN SECTION */}
      <CustomBlock className="input-section lighter-grey--sbg flex-grow-1 p-5 d-flex align-items-center justify-content-center" style={{ maxWidth: '60%' }}>
        {!forgotPasswordBlockVisible ? (
          <FormBlock style={{ maxWidth: '600px' }}>
            <ContentHeader
              title="Account Login"
              headerSize="xl"
            />
            <InputBlock
              id="email"
              label="Username"
              placeholder="Enter username"
              backgroundColor="white--sbg"
              errorMessage={formik.errors.email}
              inputState={`${helper.getInputClasses(formik, 'email')}`}
              {...formik.getFieldProps('email')}
              iconLeft={(
                <FiUser
                  size={iconSize}
                  className="dark-grey--clr"
                />
            )}
            />
            <InputBlock
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              label="Password"
              placeholder="Enter password"
              backgroundColor="white--sbg"
              iconLeft={(
                <FiLock
                  size={iconSize}
                  className="dark-grey--clr"
                />
            )}
              inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
              inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
              {...formik.getFieldProps('password')}
              errorMessage={formik.errors.password}
              inputState={`${helper.getInputClasses(formik, 'password')}`}
            />
            <CustomBlock className="button-container--flex-end">
              <CustomBlock className="d-flex gap-2">
                <Button
                  // type="submit"
                  text="Return to Homepage"
                  className="secondary--bg"
                // isLoading={formik.isSubmitting}
                // isDisabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => history.push('/')}
                />
                <Button
                  type="submit"
                  text="Sign In"
                  className="primary--bg"
                  isLoading={formik.isSubmitting}
                  isDisabled={formik.isSubmitting || !formik.isValid}
                  onClick={formik.submitForm}
                />
              </CustomBlock>
            </CustomBlock>
          </FormBlock>
        )
          : (
            <ForgotPasswordBlock
              returnActionOnClick={() => {
                setForgotPasswordBlockVisible(!forgotPasswordBlockVisible);
              }}
            />
          )}
      </CustomBlock>
    </CustomBlock>

  // <CustomBlock className="main-container">
  //   <CustomBlock className="authenticate-page flex-wrap login reverse">
  //     <CustomBlock className="input-section lighter-grey--sbg">
  //       {
  //         !forgotPasswordBlockVisible
  //           ? (
  //             <FormBlock>
  //               <ContentHeader
  //                 title="Account Login"
  //                 headerSize="xl"
  //               />

  //               <InputBlock
  //                 id="email"
  //                 label="Username"
  //                 placeholder="Enter username"
  //                 backgroundColor="white--sbg"
  //                 errorMessage={formik.errors.email}
  //                 inputState={`${helper.getInputClasses(formik, 'email')}`}
  //                 {...formik.getFieldProps('email')}
  //                 iconLeft={(
  //                   <FiUser
  //                     size={iconSize}
  //                     className="dark-grey--clr"
  //                   />
  //                 )}
  //               />

  //               <InputBlock
  //                 id="password"
  //                 type={passwordVisible ? 'text' : 'password'}
  //                 label="Password"
  //                 placeholder="Enter password"
  //                 backgroundColor="white--sbg"
  //                 iconLeft={(
  //                   <FiLock
  //                     size={iconSize}
  //                     className="dark-grey--clr"
  //                   />
  //                 )}
  //                 inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
  //                 inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
  //                 {...formik.getFieldProps('password')}
  //                 errorMessage={formik.errors.password}
  //                 inputState={`${helper.getInputClasses(formik, 'password')}`}
  //                 {...formik.getFieldProps('password')}
  //               />
  //               <CustomBlock className="button-container--flex-end">
  //                 <Button
  //                   type="submit"
  //                   text="Sign In"
  //                   className="primary--bg"
  //                   isLoading={formik.isSubmitting}
  //                   isDisabled={formik.isSubmitting || !formik.isValid}
  //                   onClick={formik.submitForm}
  //                 />
  //               </CustomBlock>
  //             </FormBlock>
  //           )
  //           : (
  //             <ForgotPasswordBlock
  //               returnActionOnClick={() => {
  //                 setForgotPasswordBlockVisible(!forgotPasswordBlockVisible);
  //               }}
  //             />
  //           )
  //       }
  //     </CustomBlock>
  //     <CustomBlock className="logo-section white--sbg">
  //       <CustomBlock className="description-block">
  //         <CustomBlock className="w-100">
  //           <Image
  //             source={Logo}
  //             // className="pb-20"
  //             // containerClassName="w-75 mx-auto"
  //             containerClassName="logo-container"
  //           />
  //         </CustomBlock>
  //       </CustomBlock>

  //       <CustomBlock className="">
  //         <p className="size-xxxs primary--clr fw-400">
  //           Copyright ©
  //           {' '}
  //           {new Date().getFullYear()}
  //           {' '}
  //           Geaux Engage. All rights reserved.
  //         </p>
  //       </CustomBlock>
  //     </CustomBlock>
  //   </CustomBlock>
  // </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertToast.actions })(LoginPage);