import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Members/';

const getAllMembers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllMembers`, constants.REQUIRE_INTERCEPTORS);

const getMemberById = async (memberId) => axiosInstance.get(`${ENDPOINTORIGIN}GetMemberById?memberId=${memberId}`, constants.REQUIRE_INTERCEPTORS);

const getMembersByType = async (memberType) => axiosInstance.get(`${ENDPOINTORIGIN}GetMembersByType?type=${memberType}`, constants.REQUIRE_INTERCEPTORS);

const createMember = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateMember`, model, constants.REQUIRE_INTERCEPTORS);

const updateMember = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateMember`, model, constants.REQUIRE_INTERCEPTORS);

const deleteMember = async (memberId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteMember?memberId=${memberId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllMembers,
  getMemberById,
  getMembersByType,
  createMember,
  updateMember,
  deleteMember
};