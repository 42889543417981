import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'SubscriptionPlan/';

const getAllSubscriptionPlans = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllSubscriptionPlans`, constants.REQUIRE_INTERCEPTORS);

const getSubscriptionPlanById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetSubscriptionPlanById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getSubscriptionPlanByName = async (name) => axiosInstance.get(`${ENDPOINTORIGIN}GetSubscriptionPlanByName?name=${name}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllSubscriptionPlans,
  getSubscriptionPlanById,
  getSubscriptionPlanByName,
};