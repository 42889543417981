import React from 'react';
import { Redirect } from 'react-router-dom';
// ROUTES
import CMSRoute from './CMSRoute';

const MemberRoute = (props) => {
  const { hasAccess, accessLevel } = props;
  let redirectPath;

  const registrationPage = '/geaux-engage-registration';
  const profilePage = '/geaux-engage-member-profile';
  const adminPage = '/admin/management/users';

  if (!hasAccess) {
    if (accessLevel === 0) {
      redirectPath = registrationPage;
    } else if (accessLevel > 0 && accessLevel <= 30) {
      redirectPath = profilePage;
    } else if (accessLevel >= 40) {
      redirectPath = adminPage;
    }
  }

  return (
    hasAccess ? (
      <CMSRoute {...props} />
    ) : (
      <Redirect to={redirectPath} />
    )
  );
};

export default MemberRoute;