/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// ICONS
import { FaRegCheckCircle } from 'react-icons/fa';
import { BiExpand, BiCollapse } from 'react-icons/bi';
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import InfiniteScroller from '../../../components/InfiniteScroller';
import Button from '../../../components/Button';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// HELPERS AND SERVICES
import * as memberService from '../../../services/management/memberService';

const IndustrialMembersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [industrialMembers, setIndustrialMembers] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    setIsLoading(true);
    memberService.getMembersByType('Industrial').then((res) => {
      const transformedMembers = res.map((member) => ({
        id: member.id,
        src: `${process.env.REACT_APP_API_URL}Attachments${member.imageFilePath}`,
        alt: member.name,
        title: member.name,
        companyName: member.name,
        companyDescription: member.description,
        companyLink: member.primaryLink
      }));

      setIndustrialMembers(transformedMembers);
    }).catch(() => {
      // showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Industrial"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="pt-40">
        <InfiniteScroller
          logos={industrialMembers}
          imageHeight="40"
          imageSpacing="100"
          scrollSpeed="1.5"
          // scrollDirection="right"
          desaturateImages
        />
      </CustomBlock>

      <CustomBlock className="main-content pb-100">
        {/* CONTENT PAGE INTRO */}
        <ContentSection
          isTextOnly
          title="Industrial Referral Members"
          description={(
            <>
              <p className="mb-20">Join the Industrial Referral Members at Geaux Engage and plug into a network where every connection counts. It&apos;s a place to meet industry pros ready to partner up and pass on valuable insights. Together, we&apos;re all about:</p>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                <p>Networking in industrial sectors.</p>
              </CustomBlock>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                <p>Educating peers, turning competitors into referral sources.</p>
              </CustomBlock>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                <p>Enhanced industry-specific networking for business growth.</p>
              </CustomBlock>
            </>
            )}
        />

        <CustomBlock className="content-section">
          <CustomBlock className="d-flex flex-column w-100">
            {/* ACTIONS */}
            <CustomBlock className="member-listing-actions-container mb-40">
              <CustomBlock className="toggle-button-group">
                <CustomBlock
                  className={`toggle-button ${isExpanded ? 'selected' : ''}`}
                  onClick={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                >
                  <BiExpand size={22} />
                  <p className="text">Expand</p>
                </CustomBlock>

                <CustomBlock
                  className={`toggle-button ${isExpanded ? '' : 'selected'}`}
                  onClick={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                >
                  <BiCollapse size={22} />
                  <p className="text">Collapse</p>
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
            
            {/* MEMBERS LISTING */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              gutter="80px"
              className="members compact"
            >
              <Masonry>
                {industrialMembers.map((member) => (
                  <CustomBlock
                    className="member"
                    onDoubleClick={() => setIsExpanded((prevState) => !prevState)}
                  >
                    <img
                      src={member.src}
                      alt={member.alt}
                      title={member.title}
                      className={`logo ${member.imageSize && member.imageSize}`}
                    />

                    <CustomBlock
                      className="content-container"
                      style={
                    isExpanded
                      ? { maxHeight: '1000px', opacity: '100%', visibility: 'visible' }
                      : { maxHeight: '0', opacity: '0%', visibility: 'hidden' }
                  }
                    >
                      <p className="title">{member.title}</p>
                      <p className="description mb-40">{member.companyDescription}</p>
                    </CustomBlock>

                    <CustomBlock className="actions-container">
                      <Button
                        type="button"
                        text="Visit Website"
                        className="button"
                        onClick={() => navigateToLink(member.companyLink)}
                        size="mdlg"
                      />
                    </CustomBlock>
                  </CustomBlock>
                ))}
              </Masonry>
            </ResponsiveMasonry>

          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default IndustrialMembersPage;