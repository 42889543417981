/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
// ASSETS - CONTACT FORM PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ContactFormPage = (props) => {
  const { showAlert, history } = props;
  const [isLoading, setIsLoading] = useState(false);

  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact Us"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <CustomBlock className="content-section">
            <ContactForm
              formConfig={formConfig}
              onSubmit={(values) => submitForm(values)}
              title="Get In Touch"
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactFormPage);