/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
import LouisianaMap from '../../../../../components/LouisianaMap';
// ASSETS
import AnimatedLogo from '../../../../../components/AnimatedLogo';

const GeauxEngageNetworks = {
  Default: 'Connect with peers in networks like EBR Parish and River Parishes, each fostering growth and collaboration. Your ideal business community is here.',
  EBRParish: 'EBR Parish\'s business scene is a blend of innovation and strategy, a perfect fit for Geaux Engage members seeking vibrant growth.',
  RiverParishes: 'In the River Parishes, history and culture create a unique networking backdrop, ideal for meaningful Geaux Engage connections.',
  WestsideParishes: 'Westside Parishes stand out for their dynamic growth and industrial prowess, a great setting for Geaux Engage’s active networking.',
  Northshore: 'Northshore\'s focus on educational excellence and business balance makes it an excellent match for Geaux Engage’s community-oriented approach.',
  GreaterNewOrleans: 'Greater New Orleans: Where culture and commerce flourish across Orleans, Jefferson, St.Bernard, and Plaquemines.',
};

const LouisianaMapSection = (props) => {
  const { history } = props;
  const [selectedNetwork, setSelectedParish] = useState('EBRParish');
  
  return (
    <CustomBlock className="content-section p-0">
      <Container fluid className="p-0">
        <Row className="p-0">
          <Col lg={6} className="p-0 mb-30">
            <CustomBlock className="d-flex flex-column justify-content-start h-100">
              <h3 className="size-xl primary--clr fw-800 mb-20">
                Real Connections, Real Growth
              </h3>

              {/* NETWORK SELECT OPTIONS */}
              <CustomBlock className="d-flex flex-wrap gap-10 mb-20">
                <Button
                  text="EBR Parish"
                  className={`${selectedNetwork === 'EBRParish' ? 'info--bg' : 'grey--bg'} circle size-xxxs`}
                  size="xs"
                  onClick={() => setSelectedParish('EBRParish')}
                />
                <Button
                  text="River Parishes"
                  className={`${selectedNetwork === 'RiverParishes' ? 'info--bg' : 'grey--bg'} circle size-xxxs`}
                  size="xs"
                  onClick={() => setSelectedParish('RiverParishes')}
                />
                <Button
                  text="Westside Parishes"
                  className={`${selectedNetwork === 'Westside' ? 'info--bg' : 'grey--bg'} circle size-xxxs`}
                  size="xs"
                  onClick={() => setSelectedParish('Westside')}
                />
                <Button
                  text="Northshore"
                  className={`${selectedNetwork === 'Northshore' ? 'info--bg' : 'grey--bg'} circle size-xxxs`}
                  size="xs"
                  onClick={() => setSelectedParish('Northshore')}
                />
                <Button
                  text="Greater New Orleans"
                  className={`${selectedNetwork === 'GreaterNewOrleans' ? 'info--bg' : 'grey--bg'} circle size-xxxs`}
                  size="xs"
                  onClick={() => setSelectedParish('GreaterNewOrleans')}
                />
              </CustomBlock>

              {/* SELECTED NETWORK DESCRIPTION */}
              <CustomBlock className="mb-10">
                {/* DEFAULT */}
                {!GeauxEngageNetworks[selectedNetwork] && (
                  <Fade>
                    <p className="mid-grey--clr size-md">
                      {GeauxEngageNetworks.Default}
                    </p>
                  </Fade>
                )}
                {/* SELECTED */}
                {GeauxEngageNetworks[selectedNetwork] && (
                  <Fade>
                    <p className="mid-grey--clr size-md">
                      {GeauxEngageNetworks[selectedNetwork]}
                    </p>
                  </Fade>
                )}
              </CustomBlock>
            </CustomBlock>
          </Col>

          <Col lg={6} className="">
            <LouisianaMap
                // width="650px"
                // width="400px"
              selectedNetwork={selectedNetwork}
            />
          </Col>
        </Row>

      </Container>
    </CustomBlock>
  );
};

export default LouisianaMapSection;