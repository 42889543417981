/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// ASSETS
import { TbCircleCheckFilled } from 'react-icons/tb';
import { Modal } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
// COMPONENTS
import CustomBlock from '../../../../components/CustomBlock';
import Button from '../../../../components/Button';
import Overlay from '../../../../components/Overlay';
import SelectBlock from '../../../../components/SelectBlock';
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as subscriptionService from '../../../../services/subscription/subscriptionService';
import * as subscriptionPlanService from '../../../../services/subscription/subscriptionPlanService';
import * as UserService from '../../../../services/management/userService';
import * as authRedux from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';

const subscriptionPlanBenefits = [
  {
    name: 'Engaged Subscriber',
    benefits: [
      'Profile Listed on Engage Pages',
      'Access to Events and Meetings (as defined by admin)',
    ],
  },
  {
    name: 'Engaged Member',
    benefits: [
      'Vetted Referral Partner Status',
      'Profile Listed on Engage Pages',
      'Access to Private Members-Only Pages',
      'Access to Events and Meetings (Members Only and Open)',
      'Charter Member Special Benefits',
    ],
  },
  {
    name: 'Engaged Sponsor',
    benefits: [
      'Vetted Referral Partner Status',
      'Profile Listed on Engage Pages',
      'Access to Private Members-Only Pages',
      'Access to Events and Meetings (Members Only and Open)',
      'Charter Member Special Benefits',
    ],
  },
];

const AccountSettingsModal = (props) => {
  const { auth, showSettingsModal, setShowSettingsModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState('subscription');
  const [userSubscription, setUserSubscription] = useState([]);
  // SUBERSCRIPTION PLAN
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionPlanOptions, setSubscriptionPlanOptions] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);
  
  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const fetchUserSubscription = async () => {
    try {
      setIsLoading(true);
      const data = await subscriptionService.getLoggedInUserSubscription();
      setUserSubscription(data);
    } catch (error) {
      // showAlert({ text: error.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubscriptionPlans = async () => {
    try {
      setIsLoading(true);
      const data = await subscriptionPlanService.getAllSubscriptionPlans();
      const filteredPlans = data.filter((plan) => plan.name !== userSubscription.name);

      const options = filteredPlans.map((plan) => ({
        value: plan.planId,
        label: plan.name,
      }));
  
      setSubscriptionPlans(filteredPlans);
      setSubscriptionPlanOptions(options);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscriptionPlanChange = (option) => {
    if (!option) {
      setSelectedSubscriptionPlan(null);
      return;
    }
  
    const selectedPlan = subscriptionPlans.find((plan) => option.value === plan.planId);
    const selectedPlanWithBenefits = {
      ...selectedPlan,
      benefits: subscriptionPlanBenefits.find(
        (item) => item.name === selectedPlan.name
      )?.benefits || [],
    };
  
    setSelectedSubscriptionPlan({
      ...selectedPlanWithBenefits,
      value: option.value,
      label: option.label,
    });
  };

  const handleUpdateSubscription = async () => {
    console.log('handleUpdateSubscription');
    console.log('SELECTED PLAN \n', selectedSubscriptionPlan);

    // try {
    //   setIsLoading(true);
    //   await subscriptionService.updateSubscription({ newSubscriptionPlanId: selectedSubscriptionPlan.value });
    //   showAlert({ text: 'Subscription updated successfully!', state: 'success' });
    //   fetchUserSubscription();
    // } catch (error) {
    //   // showAlert({ text: error.message, state: 'danger' });
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const handleCancelSubscription = async () => {
    console.log('handleCancelSubscription');

    try {
      // console.log('CANCELLING SUBSCRIPTION \n', subscriptionId);
      const res = await subscriptionService.cancelSubscription(userSubscription.id, auth.user.id);
      console.log('CANCELLING SUBSCRIPTION RES \n', res);
      setCurrentStep('cancel-success');
    } catch (error) {
      // showAlert({ text: 'Error canceling subscription', state: 'danger' });
    }
  };

  const handleModalClose = () => {
    setShowSettingsModal(false);
  };

  const columns = [
    {
      name: 'name',
      label: 'Plan',
      options: { sort: false }
    },
    {
      name: 'dateCreated',
      label: 'Purchase Date',
      options: {
        sort: false,
        customBodyRender: (value) => moment(value).format('MM/DD/YYYY')
      }
    },
    {
      name: 'dateExpiration',
      label: 'Expiration Date',
      options: {
        sort: false,
        customBodyRender: (value) => moment(value).format('MM/DD/YYYY')
      }
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        sort: false,
        customBodyRender: (value) => `$${parseFloat(value).toFixed(2)}`
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: false,
        customBodyRender: (value) => {
          const statusColors = {
            active: 'success',
            expired: 'danger',
            cancelled: 'danger',
            pending: 'warning',
          };
  
          const colorClass = statusColors[value?.toLowerCase()] || 'secondary';
          return <p className={`text-${colorClass}`}>{value}</p>;
        }
      }
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    selectableRows: 'none',
    responsive: 'vertical',
    textLabels: { body: { noMatch: 'No Subscription History Found' } },
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Modal
        show={showSettingsModal}
        onHide={handleModalClose}
        centered
        size={currentStep === 'subscription' && 'lg'}
      >
        <Modal.Header closeButton className="border-0 pb-2">
          <Modal.Title className="size-mdlg secondary-animated-gradient--clr fw-700 text-center">
            {currentStep === 'subscription' && 'Subscription'}
            {currentStep === 'manage' && 'Manage Subscription'}
            {currentStep === 'update' && 'Update Subscription'}
            {currentStep === 'cancel' && 'Cancel Subscription'}
            {currentStep === 'cancel-success' && 'Subscription Cancelled'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-30">
          {(currentStep === 'subscription' && userSubscription) && (
            <CustomBlock className="pb-20">
              <MUIDataTable
                data={[userSubscription]}
                columns={columns}
                options={options}
              />
            </CustomBlock>
          )}

          {currentStep === 'manage' && (
            <CustomBlock className="d-flex flex-column align-items-center gap-20 pt-20">
              <Button
                text="Update Subscription Plan"
                onClick={async () => {
                  await fetchSubscriptionPlans();
                  setCurrentStep('update');
                }}
                // className="size-xs round border-0 primary-lighter--bg--gradient w-100 py-10"
                className="size-xs round border-0 w-100 py-10 grey--sbg"
                style={{ maxWidth: '80%' }}
                isDisabled
              />
              <Button
                text="Cancel Subscription"
                onClick={() => setCurrentStep('cancel')}
                // className="size-xs round border-0 danger--bg--gradient w-100 py-10"
                className="size-xs round border-0 w-100 py-10 grey--sbg"
                style={{ maxWidth: '80%' }}
                isDisabled
              />
            </CustomBlock>
          )}

          {currentStep === 'update' && (
            <>
              <CustomBlock className="mb-30">
                <SelectBlock
                  isRequired
                  isClearable
                  label="Membership Tier"
                  labelColor="dark--clr"
                  value={selectedSubscriptionPlan || null}
                  onChange={handleSubscriptionPlanChange}
                  options={subscriptionPlanOptions}
                  inputState={selectedSubscriptionPlan ? 'valid' : 'invalid'}
                />
              </CustomBlock>
            
              {selectedSubscriptionPlan && (
              <>
                <p className="size-mdlg primary-animated-gradient--clr fw-700 lh-normal mb-2">
                  {selectedSubscriptionPlan.name}
                </p>
            
                <p className="size-sm dark-grey--clr lh-normal mb-2">
                  Membership Cost:
                  <span className="size-mdlg fw-600 lh-normal">
                    {' '}
                    ${selectedSubscriptionPlan.amount}
                    <span className="size-xs dark-grey--clr fw-400">&#65295;Year</span>
                  </span>
                </p>
            
                <>
                  <p className="size-sm dark-grey--clr lh-normal mb-2">
                    Membership Benefits:
                  </p>
                  <ul className="list-unstyled size-sm mb-3">
                    {selectedSubscriptionPlan.benefits.map((benefit, index) => (
                      <li className="size-xs d-flex align-items-center gy-2 mb-2">
                        <TbCircleCheckFilled size={22} className="flex-shrink-0 success--clr" style={{ opacity: '0.85' }} />
                        <span className="lh-normal dark-grey--clr">{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </>
              </>
              )}
            </>
          )}

          {currentStep === 'cancel' && (
            <CustomBlock className="d-flex flex-column align-items-center gap-20 pt-20">
              <p className="size-sm text-dark fw-500">
                Are you sure you want to cancel your subscription?
              </p>
            </CustomBlock>
          )}

          {currentStep === 'cancel-success' && (
            <CustomBlock className="d-flex flex-column align-items-start gap-20 pt-10">
              <p className="size-sm primary-animated-gradient--clr fw-600">
                <span className="size-mdlg fw-700">Thank you</span> for being an {userSubscription?.name}.
              </p>
              <p className="size-sm text-dark fw-500">
                Your subscription has been cancelled and will not auto-renew. Your current membership expires on <span className="primary-animated-gradient--clr fw-600">{moment(userSubscription?.dateExpiration).format('MMMM Do, YYYY')}</span>.
              </p>
            </CustomBlock>
          )}
        </Modal.Body>
        
        <Modal.Footer className="border-0">
          {currentStep === 'subscription' && (
            <div className="d-flex align-items-center justify-content-end flex-wrap gap-10">
              <Button
                text="Close"
                onClick={() => setShowSettingsModal(false)}
                className="round border-0 bg-secondary"
              />
              <Button
                text="Manage Subscription"
                onClick={() => setCurrentStep('manage')}
                className="round border-0 primary--bg"
              />
            </div>
          )}

          {currentStep === 'manage' && (
            <Button
              text="Return"
              onClick={() => setCurrentStep('subscription')}
              className="round border-0 bg-secondary"
            />
          )}

          {currentStep === 'update' && (
            <div className="d-flex gap-10">
              <Button
                text="Return"
                onClick={() => {
                  setSelectedSubscriptionPlan(null);
                  setCurrentStep('manage');
                }}
                className="round border-0 bg-secondary"
              />
              <Button
                text="Change Plan"
                onClick={() => {
                  // setCurrentStep('manage');
                  handleUpdateSubscription();
                }}
                className={`round border-0 ${selectedSubscriptionPlan ? 'success--bg' : 'grey--sbg'}`}
                isDisabled={!selectedSubscriptionPlan}
              />
            </div>
          )}

          {currentStep === 'cancel' && (
            <div className="d-flex gap-10">
              <Button
                text="Return"
                onClick={() => setCurrentStep('manage')}
                className="round border-0 bg-secondary"
              />
              <Button
                text="Cancel Subscription"
                onClick={handleCancelSubscription}
                className="round border-0 bg-danger"
              />
            </div>
          )}

          {currentStep === 'cancel-success' && (
            <Button
              text="Close"
              onClick={() => setShowSettingsModal(false)}
              className="round border-0 bg-secondary"
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...authRedux.actions, ...alert.actions })(AccountSettingsModal);