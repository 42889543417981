/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import { FaRegCheckCircle } from 'react-icons/fa';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// ICONS
import { BiExpand, BiCollapse } from 'react-icons/bi';
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import InfiniteScroller from '../../../components/InfiniteScroller';
import Button from '../../../components/Button';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// HELPERS AND SERVICES
import * as memberService from '../../../services/management/memberService';

const CommercialMembersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [commercialMembers, setCommercialMembers] = useState([]);

  const fetchMembers = () => {
    setIsLoading(true);
    memberService.getMembersByType('Commercial').then((res) => {
      const transformedMembers = res.map((member) => ({
        id: member.id,
        src: `${process.env.REACT_APP_API_URL}Attachments${member.imageFilePath}`,
        alt: member.name,
        title: member.name,
        companyName: member.name,
        companyDescription: member.description,
        companyLink: member.primaryLink
      }));

      setCommercialMembers(transformedMembers);
    }).catch(() => {
      // showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Commercial"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="pt-40">
        <InfiniteScroller
          logos={commercialMembers}
          imageHeight="40"
          imageSpacing="100"
          desaturateImages
          scrollDirection="left"
          scrollSpeed="3"
        />
      </CustomBlock>

      <CustomBlock className="main-content pb-100">
        {/* CONTENT PAGE INTRO */}
        <ContentSection
          isTextOnly
          title="Commercial Referral Members"
          description={(
            <>
              <p className="mb-20">Step into the Geaux Engage Commercial Network, a space where connections spark progress and open doors to new business opportunities. Join other forward-thinking professionals who are just as dedicated to sharing advice and growing together. Here, we&apos;re all about:</p>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                Creating bonds that bolster business.
              </CustomBlock>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                Gaining insights and swapping stories with peers.
              </CustomBlock>
              <CustomBlock className="mb-20" style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCheckCircle className="mr-10" style={{ minWidth: '30px', color: '#62cf62' }} />
                Driving your commercial success through community strength.
              </CustomBlock>
            </>
            )}
        />
        
        <CustomBlock className="content-section">
          <CustomBlock className="d-flex flex-column w-100">
            {/* ACTIONS */}
            <CustomBlock className="member-listing-actions-container mb-40">
              <CustomBlock className="toggle-button-group">
                <CustomBlock
                  className={`toggle-button ${isExpanded ? 'selected' : ''}`}
                  onClick={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                >
                  <BiExpand size={22} />
                  <p className="text">Expand</p>
                </CustomBlock>

                <CustomBlock
                  className={`toggle-button ${isExpanded ? '' : 'selected'}`}
                  onClick={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                >
                  <BiCollapse size={22} />
                  <p className="text">Collapse</p>
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
            
            {/* MEMBERS LISTING */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              gutter="80px"
              className="members w-100"
            >
              <Masonry>
                {
              commercialMembers.map((member) => (
                <CustomBlock
                  className="member"
                  onDoubleClick={() => setIsExpanded((prevState) => !prevState)}
                >
                  <img
                    src={member.src}
                    alt={member.alt}
                    title={member.title}
                    className={`logo ${member.imageSize && member.imageSize}`}
                    style={{ opacity: '100% !important' }}
                  />

                  <CustomBlock
                    className="content-container"
                    style={
                      isExpanded
                        ? { maxHeight: '1000px', opacity: '100%', visibility: 'visible' }
                        : { maxHeight: '0', opacity: '0%', visibility: 'hidden' }
                    }
                  >
                    <p className="title">{member.title}</p>
                    <p className="description mb-40">{member.companyDescription}</p>
                  </CustomBlock>

                  <CustomBlock className="actions-container">
                    <Button
                      type="button"
                      text="Visit Website"
                      className="button"
                      onClick={() => navigateToLink(member.companyLink)}
                      size="mdlg"
                    />
                  </CustomBlock>
                </CustomBlock>
              ))
            }
              </Masonry>
            </ResponsiveMasonry>
          </CustomBlock>
        </CustomBlock>

      </CustomBlock>
    </>
  );
};

export default CommercialMembersPage;