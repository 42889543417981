/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/user/RegisterUserValidator';
import UpdatePasswordValidator from '../../../../helpers/validators/user/UpdatePasswordValidator';
import EditUserValidator from '../../../../helpers/validators/user/EditUserValidator';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const userRegisterModel = {
  fullName: '',
  email: '',
  phone: '',
  username: '',
  password: '',
  confirmPassword: '',
  isActive: true,
  isAdmin: false
};

const initialUpdatePasswordModel = {
  id: 0,
  password: '',
  confirmPassword: '',
};

const initialEditModel = {
  id: 0,
  fullName: '',
  email: '',
  phone: '',
  username: '',
  isActive: false,
  isAdmin: false
};

const UserListingPage = (props) => {
  const { showAlert, auth } = props;
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordRegisterModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePasswordModel, setUpdatePasswordModel] = useState(initialUpdatePasswordModel);
  const [editUserModel, setEditUserModel] = useState(initialEditModel);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getAllUsers();
  }, []);

  const regiser_user_formik = useFormik({
    initialValues: userRegisterModel,
    validationSchema: RegisterUserValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      registerUser(values);
    },
  });

  const update_password_user_formik = useFormik({
    initialValues: updatePasswordModel,
    validationSchema: UpdatePasswordValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserPassword(values);
    },
  });

  const edit_user_formik = useFormik({
    initialValues: editUserModel,
    validationSchema: EditUserValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      editUser(values);
    },
  });

  const getUserDetails = (id) => {
    setIsLoading(true);
    userService.getUserById(id).then((res) => {
      setEditUserModel(res);
      setEditModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getAllUsers = () => {
    setIsLoading(true);
    userService.getAllUsers().then((res) => {
      const filteredUsers = res.filter((user) => user.isAdmin || user.isEditor);
      setUserList(filteredUsers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const registerUser = (model) => {
    setIsLoading(true);
    userService.register(model).then((res) => {
      getAllUsers();
      showAlert({ text: res.message, state: 'success' });
      closeRegisterModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeRegisterModal = () => {
    setRegisterModalVisible(false);
    regiser_user_formik.resetForm();
  };

  const closePasswordModal = () => {
    setUpdatePasswordModel(initialUpdatePasswordModel);
    update_password_user_formik.resetForm();
    setUpdatePasswordRegisterModalVisible(false);
  };

  const updateUserPassword = (model) => {
    setIsLoading(true);
    userService.changeUserPassword(model.password, model.oldPassword).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closePasswordModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const editUser = (model) => {
    setIsLoading(true);
    userService.updateUser(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closeEditModal();
      getAllUsers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
    edit_user_formik.resetForm();
    setEditUserModel(initialEditModel);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            {
              auth.isAdmin
              && (
                <Button
                  text="View"
                  className="primary--bg ml-0"
                  size="xxs"
                  onClick={() => {
                    getUserDetails(userList[dataIndex].id);
                  }}
                />
              )
            }

            {
              (auth.user.id === userList[dataIndex].id)
              && (
                <Button
                  text="Reset Password"
                  className="warning--bg ml-1"
                  size="xxs"
                  onClick={() => {
                    setUpdatePasswordRegisterModalVisible(true);
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'username',
      label: 'Username',
      options: columnOptions,
    },
    {
      name: 'fullName',
      label: 'Full Name',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is User Active?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (<p>{userList[dataIndex].isActive ? 'Yes' : 'No'}</p>)
      },
    },
    {
      name: 'isAdmin',
      label: 'Is User Admin?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (<p>{userList[dataIndex].isAdmin ? 'Yes' : 'No'}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Users"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add New User' : ''}
                primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setRegisterModalVisible(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={userList}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* REGISTER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={registerModalVisible}
        size="lg"
        contentHeader="New User"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={regiser_user_formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={closeRegisterModal}
      >
        <FormBlock onSubmit={regiser_user_formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="User Name"
                errorMessage={regiser_user_formik.errors.username}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'username')}`}
                {...regiser_user_formik.getFieldProps('username')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Full Name"
                errorMessage={regiser_user_formik.errors.fullName}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'fullName')}`}
                {...regiser_user_formik.getFieldProps('fullName')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Email"
                errorMessage={regiser_user_formik.errors.email}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'email')}`}
                {...regiser_user_formik.getFieldProps('email')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Phone Number"
                errorMessage={regiser_user_formik.errors.phone}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'phone')}`}
                {...regiser_user_formik.getFieldProps('phone')}
                mask="(999) 999-9999"
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="Password"
                placeholder="Enter password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={regiser_user_formik.errors.password}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'password')}`}
                {...regiser_user_formik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm Password"
                placeholder="Enter password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={regiser_user_formik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(regiser_user_formik, 'confirmPassword')}`}
                {...regiser_user_formik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is Admin?"
                id="isAdmin"
                {...regiser_user_formik.getFieldProps('isAdmin')}
                isChecked={regiser_user_formik.values.isAdmin}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is user active?"
                id="isActive"
                {...regiser_user_formik.getFieldProps('isActive')}
                isChecked={regiser_user_formik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* RESET PASSWORD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={updatePasswordModalVisible}
        size="lg"
        contentHeader="Reset User Password"
        primaryModalActionText="Reset"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={update_password_user_formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setUpdatePasswordRegisterModalVisible(false);
        }}
      >
        <FormBlock onSubmit={update_password_user_formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock cols={12}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="Old Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={update_password_user_formik.errors.oldPassword}
                inputState={`${helper.getInputClasses(update_password_user_formik, 'oldPassword')}`}
                {...update_password_user_formik.getFieldProps('oldPassword')}
                isRequired
              />
            </ContentBlock>
            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="New Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={update_password_user_formik.errors.password}
                inputState={`${helper.getInputClasses(update_password_user_formik, 'password')}`}
                {...update_password_user_formik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm New Password"
                placeholder="Confirm new password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={update_password_user_formik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(update_password_user_formik, 'confirmPassword')}`}
                {...update_password_user_formik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* EDIT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={editModalVisible}
        size="lg"
        contentHeader="Edit User"
        primaryModalActionText="Update"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={edit_user_formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          closeEditModal();
        }}
      >
        <FormBlock onSubmit={edit_user_formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="User Name"
                inputState="disabled"
                disabled
                isRequired
                {...edit_user_formik.getFieldProps('username')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Full Name"
                errorMessage={edit_user_formik.errors.fullName}
                inputState={`${helper.getInputClasses(edit_user_formik, 'fullName')}`}
                {...edit_user_formik.getFieldProps('fullName')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Email"
                errorMessage={edit_user_formik.errors.email}
                inputState={`${helper.getInputClasses(edit_user_formik, 'email')}`}
                {...edit_user_formik.getFieldProps('email')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Phone Number"
                errorMessage={edit_user_formik.errors.phone}
                inputState={`${helper.getInputClasses(edit_user_formik, 'phone')}`}
                {...edit_user_formik.getFieldProps('phone')}
                mask="(999) 999-9999"
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is Admin?"
                id="isAdmin"
                {...edit_user_formik.getFieldProps('isAdmin')}
                isChecked={edit_user_formik.values.isAdmin}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is user active?"
                id="isActive"
                {...edit_user_formik.getFieldProps('isActive')}
                isChecked={edit_user_formik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(UserListingPage);