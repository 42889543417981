/* eslint-disable import/no-unresolved */
// DEPENDENCIES
import React from 'react';
// import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
// import constants from './constants/constants';
import Routes from './Routes';
// CUSTOM COMPONENTS
// STYLESHEETS
import 'react-table/react-table.css';
import './assets/css/main.css';
import './assets/custom/custom.css';

const App = () => (
  // <HttpsRedirect>
  <PayPalScriptProvider
    options={{
      'client-id': process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
      'enable-funding': 'card',
      'disable-funding': 'paylater',
      vault: true,
      intent: 'subscription',
    }}
  >
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </PayPalScriptProvider>
  // </HttpsRedirect>
);

export default App;