/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, {
  useEffect, useState, useRef
} from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
// COMPONENTS
import { ButtonGroup, Button as BSButton } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff,
  FiCalendar
} from 'react-icons/fi';
import {
  TbCheck, TbX, TbCalendarUser, TbUserShield, TbCalendarClock, TbClockExclamation, TbPhotoCirclePlus
} from 'react-icons/tb';
// COMPONENTS
import { values } from 'lodash';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
import FormBlock from '../../../../components/FormBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import FileUploadBlock from '../../../../components/FileUploadBlock';
// ASSETS
import defaultProfileImage from '../../../../assets/client/default-member-profile-image.png';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import * as membershipService from '../../../../services/management/membershipService';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/user/RegisterUserValidator';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const initialValues = {
  id: 0,
  message: '',
};

const MemberAdminSettingsPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [membershipOptions, setMembershipOptions] = useState([]);
  // OTHER
  const iconSize = 22;
  const iconColor = 'white--clr';
  
  useEffect(() => {
    getMembershipOptions();
  }, []);

  const getMembershipOptions = async () => {
    const options = await helper.getMembershipOptions();
    console.log('MEMBERSHIP OPTIONS \n', options);
    setMembershipOptions(options);
  };

  // const formik = useFormik({
  //   initialValues: selectedMember || membershipModel,
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     firstName: Yup.string().required('Please enter your first name').max(100),
  //     lastName: Yup.string().required('Please enter your last name').max(100),
  //     emailAddress: Yup.string()
  //       .required('Please enter your email address')
  //       .email('Please enter a valid email')
  //       .max(100)
  //       .test('checkEmailExists', 'Email already exists', (value) => {
  //         if (!value) return true;

  //         const userExists = userList.some((user) => user.emailAddress === value && user.id !== selectedMember?.id);
  //         return !userExists;
  //       }),
  //     phoneNumber: Yup.string().nullable(),
  //     companyName: Yup.string().nullable().max(255),
  //     // addressLine1: Yup.string().required('Please enter your street address').max(255),
  //     // addressLine2: Yup.string().nullable().max(255),
  //     // city: Yup.string().required('Please enter your city').max(100),
  //     // state: Yup.string().required('Please enter your state').max(100),
  //     // zipCode: Yup.string().required('Please enter your ZIP/postal code').max(20),
  //     password: Yup.string().when('id', {
  //       is: undefined,
  //       then: Yup.string().required('Password is required').min(8).max(100),
  //     }),
  //     membershipTier: Yup.string().required('Please select a membership tier'),
  //     // MembershipDateCreated: Yup.string().when('membershipTier', {
  //     //   is: (val) => val && val.length > 0,
  //     //   then: Yup.string().required('Membership start date is required'),
  //     // }),
  //     // imageFile: Yup.string().nullable(),
  //   }),
  //   onSubmit: async (values, { setSubmitting }) => {
  //     saveMember(values, setSubmitting);
  //     // try {
  //     //   setSubmitting(true);
  //     //   // console.log('SUBMITTED VALUES \n', values);
  //     //   saveMember(values, setSubmitting);
  //     // } catch (error) {
  //     //   showAlert({
  //     //     text: error.response?.data?.Message || error.message || 'Error validating email.',
  //     //     state: 'error',
  //     //   });
  //     //   setSubmitting(false);
  //     // }
  //   },
  // });

  const formik = useFormik({
    initialValues: { initialValues },
    // validationSchema: Yup.object({
    //   firstName: Yup.string()
    //     .required('First name is required')
    //     .max(100, 'First name must be 100 characters or less'),
    //   lastName: Yup.string()
    //     .required('Last name is required')
    //     .max(100, 'Last name must be 100 characters or less'),
    //   email: Yup.string()
    //     .required('Email is required')
    //     .email('Invalid email address')
    //     .max(100, 'Email must be 100 characters or less'),
    //   password: Yup.string()
    //     .required('Password is required')
    //     .min(8, 'Password must be at least 8 characters')
    //     .max(100, 'Password must be 100 characters or less'),
    // }),
    onSubmit: (values, { setSubmitting }) => {
      // console.log('Form data', values);
      setSubmitting(false);
    },
  });

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Admin Settings"
                headerSize="lg"
                // primaryButtonBackgroundColor="primary--bg"
                // primaryButtonText={auth.isAdmin ? 'Add New Member' : ''}
                // primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                // primaryButtonOnClick={() => {
                //   setSelectedMember(membershipModel);
                //   setMemberModalVisible(true);
                // }}
              />

              <FormBlock className="" onSubmit={formik.handleSubmit}>
                {/* <Section hasNoContainer>
                  <ContentBlock cols={12} className="mt-30 mb-10">
                    <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Membership Expiration</h3>
                  </ContentBlock>
                </Section> */}

                <Section hasNoContainer>
                  <ContentBlock cols={12} className="mt-30 mb-10">
                    <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Messages</h3>
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      label="Primary Admin Message"
                      placeholder="This message will be visible to all members. Announce upcoming networking events, partnership opportunities, or important platform updates."
                      value={formik.values.message || ''}
                      errorMessage={formik.touched.message && formik.errors.message}
                      inputState={`${helper.getInputClasses(formik, 'message')}`}
                      {...formik.getFieldProps('message')}
                      labelColor="dark--clr"
                    />
                  </ContentBlock>
                </Section>
              </FormBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions, ...confirmModal.actions })(MemberAdminSettingsPage);