/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import { TbArrowBigUpLines } from 'react-icons/tb';
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
import InfiniteScroller from '../../../../../components/InfiniteScroller';
import Overlay from '../../../../../components/Overlay';
// ASSETS
import GrowWithOurMembersImage from '../../../../../assets/img/content-image_grow-with-us.png';
import BusinessGrowthBackgroundTexture from '../../../../../assets/img/background-texture_business-growth.png';
// SERVICES & HELPERS
import * as memberService from '../../../../../services/management/memberService';

const MembersGrowthSection = (props) => {
  // const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [commercialMembers, setCommercialMembers] = useState([]);
  const [industrialMembers, setIndustrialMembers] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    setIsLoading(true);

    Promise.all([
      memberService.getMembersByType('Commercial'),
      memberService.getMembersByType('Industrial')
    ]).then(([commercialResponse, industrialResponse]) => {
      const transformedCommercialMembers = commercialResponse.map((member) => ({
        id: member.id,
        src: `${process.env.REACT_APP_API_URL}Attachments${member.imageFilePath}`,
        alt: member.name,
        title: member.name,
        companyName: member.name,
        companyDescription: member.description,
        companyLink: member.primaryLink
      }));

      const transformedIndustrialMembers = industrialResponse.map((member) => ({
        id: member.id,
        src: `${process.env.REACT_APP_API_URL}Attachments${member.imageFilePath}`,
        alt: member.name,
        title: member.name,
        companyName: member.name,
        companyDescription: member.description,
        companyLink: member.primaryLink
      }));

      setCommercialMembers(transformedCommercialMembers);
      setIndustrialMembers(transformedIndustrialMembers);
    }).catch((ex) => {
      // showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };
  
  return (
    <>
      {isLoading && <Overlay hasLoader /> }
      <CustomBlock className="content-section">
        <CustomBlock
          className="d-flex flex-column p-4 roundest overflow-hidden"
          style={{
            background: `radial-gradient(circle at center, #f7f7f7, #F3F3F3 50%, #F3F3F3 100%), url(${GrowWithOurMembersImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'saturation',
            maxWidth: '100%',
          }}
        >
          <CustomBlock className="mb-80">
            <h3 className="size-xl fw-800 primary--clr">Grow with Our Members</h3>
            <p className="size-md dark--clr">Connect with partners you&apos;ll trust and respect.</p>
            
            <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-20">
              <Fade direction="left">
                <Button
                  to="/referral-members"
                  text="Elevate Your Connections"
                  className="circle secondary--bg"
                  size="xs"
                  iconLeft={(
                    <Fade delay={200} direction="up">
                      <TbArrowBigUpLines size={24} />
                    </Fade>
                  )}
                />
              </Fade>
            </CustomBlock>

          </CustomBlock>

          {/* COMMERCIAL MEMBERS */}
          <CustomBlock className="mb-70">
            <InfiniteScroller
              logos={commercialMembers}
              imageHeight="45"
              imageSpacing="80"
              scrollDirection="left"
              scrollSpeed="3"
              desaturateImages
            />
          </CustomBlock>

          {/* INDUSTRIAL MEMBERS */}
          <CustomBlock className="mb-20">
            <InfiniteScroller
              logos={industrialMembers}
              imageHeight="45"
              imageSpacing="80"
              scrollDirection="right"
              scrollSpeed="1"
              desaturateImages
            />
          </CustomBlock>
        </CustomBlock>
          
        <CustomBlock
          className="xresponsive-content-section xcommercial-referral-members light-grey-gradient mb-60"
          style={{ background: `radial-gradient(circle at center, #f7f7f7, #F3F3F3 50%, #F3F3F3 100%), url(${GrowWithOurMembersImage})` }}
        />

        {/* </CustomBlock> */}
        {/* // </Fade> */}
      </CustomBlock>
    </>
  );
};

export default MembersGrowthSection;