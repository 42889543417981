/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Image from '../../../components/Image';
import ContentSection from '../../../components/ContentSection';
import AnimatedLogo from '../../../components/AnimatedLogo';
// ASSETS - CONTACT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundTexture from '../../../assets/img/background-texture-1.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const getDirectionsLink = 'https://www.google.com/maps/place/142+Del+Norte+Ave,+Denham+Springs,+LA+70726/@30.4706975,-90.9551617,17z/data=!3m1!4b1!4m6!3m5!1s0x8626bef0ef5cd50d:0x915fc5c888dc7ba6!8m2!3d30.4706975!4d-90.9551617!16s%2Fg%2F11c28v5b_f?entry=ttu';
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        {/* <ContentSection
          hasRoundCorners
          isFullWidth
          title="Connect With Us"
          description="Your dream property is just a message away. Contact us to make it a reality."
          imageSource={IntroSectionImage}
          primaryActionText="Get Directions"
          primaryActionOnClick={() => window.open(getDirectionsLink, '_blank')}
          primaryActionColor="primary--sbg"
        /> */}

        {/* <h1><span className="custom-header--primary-light">Transport</span> Logics</h1> */}
        {/* <h1 className="custom-header--primary-light">Transport Logics</h1> */}
        {/* <br /> */}

        <CustomBlock className="responsive-content-section mt-40 mb-40">
          <AnimatedLogo
            // onClick
            onTimer="6s"
            // width="200px"
            center
          />
        </CustomBlock>

        <CustomBlock className="responsive-content-section mb-80 shadow--1" style={{ backgroundImage: `url(${BackgroundTexture})`, backgroundPosition: 'cover' }}>
          {/*
            <h2 className="size-lg primary-lighter--clr fw-700 mb-10">Location</h2>
            {cmsInfo.streetAddress1 && (
              <>
                <h3 className="size-mdlg dark-grey--clr fw-700 mb-5">Physical Address</h3>
                <p className="size-sm">{cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}</p>
                <p className="size-sm">{cmsInfo.city}, {cmsInfo.state}, {cmsInfo.zipCode}</p>
              </>
            )}
            <h3 className="size-mdlg dark-grey--clr fw-700 mb-5">Mailing Address</h3>
            <p className="size-sm">P.O. Box 448</p>

            <br />
          */}

          {/* CONTACT */}
          <CustomBlock className="mb-30">
            <h2 className="size-lg primary-lighter--clr fw-700 mb-10">Contact</h2>
            {/* <h3 className="size-mdlg dark-grey--clr fw-700 mb-5">Contact Details</h3> */}
            <p className="size-sm mb-10">Email: {cmsInfo.emailAddress}</p>
            <p className="size-sm mb-10">Phone: {cmsInfo.phoneNumber}</p>
            <p className="size-sm">Fax: (225) 685-1044</p>
          </CustomBlock>

          {/* BUSINESS HOURS */}
          <CustomBlock className="">
            {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
              <>
                <h2 className="size-lg primary-lighter--clr fw-700 mt-10">Business Hours</h2>
                {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                  <CustomBlock className="mb-5">
                    <h3 className="size-sm mid-grey--clr fw-600">Weekdays</h3>

                    {cmsInfo.primaryWeekDayHours && (
                      <p className="size-sm">{cmsInfo.primaryWeekDayHours}</p>
                    )}
                    {cmsInfo.secondaryWeekDayHours && (
                      <p className="size-sm">{cmsInfo.secondaryWeekDayHours}</p>
                    )}
                  </CustomBlock>
                )}
                {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                  <CustomBlock>
                    <h3 className="size-sm mid-grey--clr fw-600">Weekends</h3>
                    {cmsInfo.primaryWeekEndHours && (
                      <p className="size-sm">{cmsInfo.primaryWeekEndHours}</p>
                    )}
                    {cmsInfo.secondaryWeekEndHours && (
                      <p className="size-sm">{cmsInfo.secondaryWeekEndHours}</p>
                    )}
                  </CustomBlock>
                )}
              </>
            )}
          </CustomBlock>

        </CustomBlock>

        {/* <CustomBlock className="responsive-content-section">
          <Image
            containerClassName="background-decoration-container"
            className="background-decoration"
            source={BackgroundDecorationImage}
          />
        </CustomBlock> */}

        <CustomBlock className="responsive-content-section content-container--card-style--with-shadow shadow--1 mb-120" style={{ position: 'relative', zIndex: '2' }}>
          <ContactForm
            formConfig={formConfig}
            onSubmit={(values) => submitForm(values)}
            title={<p className="secondary-lighter--clr mb-20"><span className="custom-header--primary-light primary-lighter--clr fw-800">Questions?</span> We Have Answers</p>}
            className="mb-20"
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);