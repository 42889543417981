/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-undef */
// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
// COMPONENTS
import Button from '../../../../components/Button';
// REDUX ACTIONS
import * as authRedux from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';

const AlertsModal = (props) => {
  const { showAlertsModal, setShowAlertsModal } = props;

  return (
    <Modal
      show={showAlertsModal}
      onHide={() => setShowAlertsModal(false)}
      centered
    >
      <Modal.Header closeButton className="border-0 pb-2">
        <Modal.Title className="size-mdlg secondary-animated-gradient--clr fw-700 text-center">
          Alerts
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-30">
        {/* RENDER ALERTS CONTENT HERE */}
        <p className="text-center">No new alerts at this time.</p>
      </Modal.Body>
      
      <Modal.Footer className="border-0">
        <Button
          text="Close"
          onClick={() => setShowAlertsModal(false)}
          className="round border-0 bg-secondary"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...authRedux.actions, ...alert.actions })(AlertsModal);