/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty */
/* eslint-disable no-console */
import store from '../../redux/store';
import axiosInstance from '../apiService';
import { actions } from '../../redux/authRedux';

const isEnabled = (config, property) => config.hasOwnProperty(property) && config[property];

// handles requests going from app to server
const requestHandler = async (request) => {
  if (isEnabled(request, 'requestHandlerEnabled')) {
    const { auth: { authToken } } = store.getState();
    request.headers.Authorization = `Bearer ${authToken}`;
  }
  request.headers['Content-Type'] = !isEnabled(request, 'fileHandler') ? 'application/json' : 'multipart/form-data';
  request.headers['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, DELETE';
  return request;
};

// handles responses coming from server to app
const successHandler = (response) => {
  if (isEnabled(response.config, 'responseHandlerEnabled')) {
    return response.data;
  }

  return response;
};

const errorHandler = async (err) => {
  if (isEnabled(err.config, 'responseHandlerEnabled')) {
    // console.log("I am handling error responses");
  }

  // trying again when 401 to refresh the token
  const originalConfig = { ...err.config };
  if (originalConfig.url !== '/Users/authenticate' && err.response) {
    // Access Token was Expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const { auth } = store.getState();
        const rs = await axiosInstance.post('/Users/refreshToken', { refreshToken: auth.refreshToken }, { headers: { Authorization: `Bearer ${auth.authToken}` } });

        if (rs.data.success) {
          store.dispatch(actions.refreshToken(rs.data.user));
          return axiosInstance(originalConfig);
        }

        throw Error(rs.data.message);
      } catch (_error) {
        await defaultErrorHandler(err);
      }
    }

    await defaultErrorHandler(err);
  } else {
    await defaultErrorHandler(err);
  }
};

const defaultErrorHandler = async (response) => {
  let message = 'Oops! Something went wrong on the server. Please contact support.';

  await Promise.resolve(response.response).then((res) => {
    if (res) {
      // message = res.data.Message;
      message = res.data.Message || res.data.message || res.data.message.message;

      // if 401 redirect to login page
      if (res.status === 401) {
        localStorage.removeItem('persist:auth-token');
        window.location = `${window.origin}/login`;
        return;
      }
      // if not authorized redirect to dashboard
      if (res.status === 403) {
        window.location = `${window.origin}/`;
      }
    }
  });

  throw Error(message);
};

export {
  requestHandler,
  successHandler,
  errorHandler
};