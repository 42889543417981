/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';
import moment from 'moment';

const BASE_URL = 'https://www.eventbriteapi.com/v3/';
const ORGANIZATION_ID = '312517213600';
const TOKEN = process.env.REACT_APP_EVENTBRITE_OAUTH_TOKEN;

const defaultStartDate = '2022-01-01'; // JANUARY 1, 2022
const defaultEndDate = moment(new Date()).format('YYYY-MM-DD'); // TODAY'S DATE

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: `Bearer ${TOKEN}` },
});

const eventModel = {
  id: '',
  imageUrl: '',
  status: '',
  title: '',
  description: '',
  summary: '',
  link: '',
  start: '',
  end: '',
  ticketsSold: 0,
  ticketsTotal: 0,
  capacity: 0,
  venueId: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

const fetchTicketInfo = async (eventId) => {
  const ticketClassesUrl = `events/${eventId}/ticket_classes/`;
  const response = await axiosInstance.get(ticketClassesUrl);
  return response.data.ticket_classes;
};

const getVenueById = async (venueId) => {
  const response = await axiosInstance.get(`venues/${venueId}/`);
  const { data } = response;
  return {
    addressLine1: data.address.address_1 || '',
    addressLine2: data.address.address_2 || '',
    city: data.address.city || '',
    state: data.address.region || '',
    zipCode: data.address.postal_code || '',
  };
};

const mapToEventModel = async (event) => {
  const tickets = await fetchTicketInfo(event.id);
  let address = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  };
  
  if (event.venue_id) {
    try {
      address = await getVenueById(event.venue_id);
    } catch (error) {
      console.error(`Error fetching venue data for event ID ${event.id}:`, error);
    }
  }

  return {
    id: event.id,
    imageUrl: event.logo?.original?.url || '',
    status: event.status,
    title: event.name.text,
    description: event.description.text,
    summary: event.summary,
    link: event.url,
    start: event.start.local,
    startString: moment(event.start.local).format('MMMM D, YYYY'),
    end: event.end.local,
    ticketsSold: tickets.reduce((acc, ticket) => acc + ticket.quantity_sold, 0),
    ticketsTotal: tickets.reduce((acc, ticket) => acc + ticket.quantity_total, 0),
    capacity: event.capacity,
    venueId: event.venue_id,
    address,
  };
};

const getAllEvents = async (pageSize = 100) => {
  const response = await axiosInstance.get(`organizations/${ORGANIZATION_ID}/events/?page_size=${pageSize}`);
  const { events } = response.data;
  return Promise.all(events.map(mapToEventModel));
};

const getEventsByStatus = async (status, pageSize = 100) => {
  const url = `organizations/${ORGANIZATION_ID}/events/?status=${status}&page_size=${pageSize}`;
  const response = await axiosInstance.get(url);
  const { events } = response.data;
  return Promise.all(events.map(mapToEventModel));
};

const getEventsByDateRange = async (startDate = defaultStartDate, endDate = defaultEndDate, pageSize = 100) => {
  const url = `organizations/${ORGANIZATION_ID}/events/?page_size=${pageSize}&start_date.range_start=${startDate}&start_date.range_end=${endDate}`;
  const response = await axiosInstance.get(url);
  const { events } = response.data;
  return Promise.all(events.map(mapToEventModel));
};

const getEventById = async (eventId) => {
  const response = await axiosInstance.get(`events/${eventId}/`);
  return mapToEventModel(response.data);
};

const getAttendeesByEventId = async (eventId) => {
  const response = await axiosInstance.get(`events/${eventId}/attendees/`);
  return response.data.attendees.map((attendee) => ({
    firstName: attendee.profile.first_name,
    lastName: attendee.profile.last_name,
    emailAddress: attendee.profile.email,
    isPresent: false,
  }));
};

const getFormattedEventData = async (events) => Promise.all(events.map(mapToEventModel));

export {
  getAllEvents,
  getEventsByStatus,
  getEventsByDateRange,
  getEventById,
  getAttendeesByEventId,
  getVenueById,
  getFormattedEventData,
  fetchTicketInfo,
};