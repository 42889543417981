/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, {
  useEffect, useState, useRef
} from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
// COMPONENTS
import { ButtonGroup, Button as BSButton } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff,
  FiCalendar
} from 'react-icons/fi';
import {
  TbCheck, TbX, TbCalendarUser, TbUserShield, TbCalendarClock, TbClockExclamation, TbPhotoCirclePlus
} from 'react-icons/tb';
// COMPONENTS
import { values } from 'lodash';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
import FormBlock from '../../../../components/FormBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import FileUploadBlock from '../../../../components/FileUploadBlock';
// ASSETS
import defaultProfileImage from '../../../../assets/client/default-member-profile-image.png';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import * as membershipService from '../../../../services/management/membershipService';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/user/RegisterUserValidator';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

// const userRegisterModel = {
//   fullName: '',
//   email: '',
//   phone: '',
//   username: '',
//   password: '',
//   confirmPassword: '',
//   isActive: true,
//   isAdmin: false
// };

const initialUpdatePasswordModel = {
  id: 0,
  password: '',
  confirmPassword: '',
};

// const initialEditModel = {
//   id: 0,
//   fullName: '',
//   email: '',
//   phone: '',
//   username: '',
//   isActive: false,
//   isAdmin: false
// };

const membershipModel = {
  id: 0,
  isActive: true,
  isEligible: false,
  isCharterMember: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  password: '',
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  membershipTier: '',
  membershipDateCreated: '',
  notes: '',
  imageFilePath: '',
};

const MemberListingPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [memberModalVisible, setMemberModalVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordRegisterModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isPasswordVisible, setIsResetPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmResetPasswordVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePasswordModel, setUpdatePasswordModel] = useState(initialUpdatePasswordModel);
  // const [editUserModel, setEditUserModel] = useState(initialEditModel);
  const [selectedMember, setSelectedMember] = useState(null);
  const [dataTableData, setDataTableData] = useState([]);
  // REGISTER MEMBER
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const filePondRef = useRef(null);
  // OTHER
  const iconSize = 22;
  const iconColor = 'white--clr';
  
  useEffect(() => {
    if (selectedMember) {
      memberFormik.setValues(selectedMember);

      // console.log('Selected Member:', selectedMember);
      // console.log('Form Initial Values:', memberFormik.values);
    }
  }, [selectedMember]);

  useEffect(() => {
    getAllMembers();
  }, []);

  useEffect(() => {
    handleFilterChange(selectedFilter);
  }, [selectedFilter, dataTableData]);

  const update_password_user_formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('New password is required')
        .min(8, 'Password must be at least 8 characters long')
        .trim(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required')
        .trim()
    }),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // console.log('UserId', selectedMember.userId);
      // console.log('Form values:', values);
      // console.log('Form errors:', update_password_user_formik.errors);
      try {
        await membershipService.changeMemberPassword(selectedMember.userId, values.password);
        showAlert({ text: 'Password reset successfully', state: 'success' });
        setUpdatePasswordRegisterModalVisible(false);
      } catch (error) {
        showAlert({ text: error.message || 'Failed to reset password', state: 'error' });
      } finally {
        setSubmitting(false);
      }
    }
  });

  const memberFormik = useFormik({
    initialValues: selectedMember || membershipModel,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Please enter your first name').max(100),
      lastName: Yup.string().required('Please enter your last name').max(100),
      emailAddress: Yup.string()
        .required('Please enter your email address')
        .email('Please enter a valid email')
        .max(100)
        .test('checkEmailExists', 'Email already exists', (value) => {
          if (!value) return true;

          const userExists = userList.some((user) => user.emailAddress === value && user.id !== selectedMember?.id);
          return !userExists;
        }),
      phoneNumber: Yup.string().nullable(),
      companyName: Yup.string().nullable().max(255),
      // addressLine1: Yup.string().required('Please enter your street address').max(255),
      // addressLine2: Yup.string().nullable().max(255),
      // city: Yup.string().required('Please enter your city').max(100),
      // state: Yup.string().required('Please enter your state').max(100),
      // zipCode: Yup.string().required('Please enter your ZIP/postal code').max(20),
      password: Yup.string().when('id', {
        is: undefined,
        then: Yup.string().required('Password is required').min(8).max(100),
      }),
      membershipTier: Yup.string().required('Please select a membership tier'),
      // MembershipDateCreated: Yup.string().when('membershipTier', {
      //   is: (val) => val && val.length > 0,
      //   then: Yup.string().required('Membership start date is required'),
      // }),
      // imageFile: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      saveMember(values, setSubmitting);
      // try {
      //   setSubmitting(true);
      //   // console.log('SUBMITTED VALUES \n', values);
      //   saveMember(values, setSubmitting);
      // } catch (error) {
      //   showAlert({
      //     text: error.response?.data?.Message || error.message || 'Error validating email.',
      //     state: 'error',
      //   });
      //   setSubmitting(false);
      // }
    },
  });
  
  const saveMember = async (values, setSubmitting) => {
    try {
      setSubmitting(true);
  
      let model = { ...values };
  
      if (uploadedImage && uploadedImage.length > 0) {
        model = { ...model, imageFile: uploadedImage[0].file };
      }

      // console.log('SUBMITTED VALUES \n', model);
  
      const formData = helper.convertJsonToFormData(model);
  
      const response = await membershipService.adminSaveUserMembership(formData);
  
      showAlert({
        text: response.data?.Message || 'Member saved successfully!',
        state: 'success',
      });
  
      setMemberModalVisible(false);
      memberFormik.resetForm();
      getAllMembers();
    } catch (error) {
      showAlert({
        text: error.response?.data?.Message || error.message || 'Error saving the member.',
        state: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getAllMembers = () => {
    setIsLoading(true);
    membershipService.getAllMemberships().then((res) => {
      const userMembers = res.filter((user) => !user.isAdmin && !user.isEditor);
      const formattedData = userMembers.map((member) => {
        const address = `${member.address.addressLine1 || ''}${
          member.address.addressLine2 ? `, ${member.address.addressLine2}` : ''
        }, ${member.address.city || ''}, ${member.address.state || ''} ${
          member.address.zipCode || ''
        }`;
  
        return {
          ...member,
          member: `${member.firstName} ${member.lastName} ${member.emailAddress} ${member.membershipTier}`,
          username: member.user?.username || 'N/A',
          fullName: `${member.firstName} ${member.lastName}`,
          address: address.trim(),
          membershipStatus: member.membershipStatus,
          isActive: member.isActive,
          isCharterMember: member.isCharterMember,
        };
      });
  
      setUserList(userMembers);
      setDataTableData(formattedData);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closePasswordModal = () => {
    setUpdatePasswordModel(initialUpdatePasswordModel);
    update_password_user_formik.resetForm();
    setUpdatePasswordRegisterModalVisible(false);
  };

  const updateUserPassword = (model) => {
    setIsLoading(true);
    userService.changeUserPassword(model.password, model.oldPassword).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closePasswordModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleFilterChange = (tier) => {
    setSelectedFilter(tier);

    if (tier === 'All') {
      setUserList(dataTableData);
    } else {
      const filteredMembers = dataTableData.filter((member) => member.membershipTier === tier);
      setUserList(filteredMembers);
    }
  };

  // useEffect(() => {
  //   if (userList) {
  //     console.log(userList);
  //   }
  // }, [userList]);

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
    pagination: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            {auth.isAdmin && (
              <CustomBlock className="d-flex flex-wrap">
                <Button
                  text="Edit"
                  className="primary--bg ml-0"
                  size="xxs"
                  onClick={() => {
                    const member = userList[dataIndex];
                    
                    // Ensure address exists before accessing properties
                    const selectedData = {
                      ...membershipModel,
                      ...member,
                      phoneNumber: member.user?.phone || member.phoneNumber || '',
                      addressLine1: member.address?.addressLine1 || '',
                      addressLine2: member.address?.addressLine2 || '',
                      city: member.address?.city || '',
                      state: member.address?.state || '',
                      zipCode: member.address?.zipCode || '',
                    };
  
                    setSelectedMember(selectedData);
                    setMemberModalVisible(true);
                  }}
                />

                <Button
                  text="Reset Password"
                  className="warning--bg ml-1"
                  size="xxs"
                  onClick={() => {
                    const member = userList[dataIndex];
                    setSelectedMember(member);
                    setUpdatePasswordModel({ id: member.userId, password: '', confirmPassword: '' });
                    setUpdatePasswordRegisterModalVisible(true);
                  }}
                />

                <Button
                  text="Remove"
                  className="danger--bg ml-1"
                  size="xxs"
                  onClick={() => {
                    const member = userList[dataIndex];
                    showConfirmModal({
                      title: 'Remove member',
                      text: 'Are you sure you want to remove this member?',
                      rightBtnText: 'Confirm',
                      btnAction: async () => {
                        try {
                          await membershipService.deleteMember(member.id);
                          showAlert({ text: 'Member removed successfully.', state: 'success' });

                          hideConfirmModal();
                          getAllMembers();
                        } catch (error) {
                          showAlert({
                            text: error.message || 'An error occurred while deleting the member.',
                            state: 'error',
                          });
                        } finally {
                          setIsLoading(false);
                        }
                      },
                    });
                  }}
                />

              </CustomBlock>
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'member',
      label: 'Profile Image',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const member = userList[dataIndex];
    
          const imageURL = member.imageFilePath !== null ? `${process.env.REACT_APP_API_URL}Attachments${member.imageFilePath}` : defaultProfileImage;
    
          return (
            <CustomBlock
              className="image profile-image"
              style={{
                background: `url(${imageURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100px',
                height: '100px',
                borderRadius: '300px',
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
              }}
            />
          );
        }
      }
    },
    {
      name: 'member',
      label: 'Member',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const user = userList[dataIndex]?.user;
          const member = userList[dataIndex];
          const fullName = member?.user?.fullName || '-';

          return (
            <div className="d-flex flex-column">
              <span className="">{fullName}</span>
              <span className="text-muted">{user?.username || '-'}</span>
            </div>
          );
        }
      }
    },
    {
      name: 'username',
      label: 'Email/Username',
      options: {
        ...columnOptions,
        display: false,
        download: true,
        searchable: true,
        customBodyRenderLite: (dataIndex) => {
          const user = userList[dataIndex]?.user;
          return <span className="">{user?.username || '-'}</span>;
        }
      }
    },
    // {
    //   name: 'address',
    //   label: 'Address',
    //   options: columnOptions,
    //   customBodyRenderLite: (dataIndex) => {
    //     const member = userList[dataIndex];
    
    //     // Safely build the full address
    //     const addressLine1 = member?.address?.addressLine1 || '';
    //     const addressLine2 = member?.address?.addressLine2 ? `, ${member.address.addressLine2}` : '';
    //     const city = member?.address?.city || '';
    //     const state = member?.address?.state || '';
    //     const zipCode = member?.address?.zipCode || '';
    
    //     // Formatting the address properly
    //     const fullAddress = `${addressLine1}${addressLine2}, ${city}, ${state} ${zipCode}`.trim().replace(/^,|,$/g, ''); // Remove leading/trailing commas if any
    
    //     return <p className="size-xs fw-700">{fullAddress || 'N/A'}</p>;
    //   }
    // },
    {
      name: 'membershipStatus',
      label: 'Membership',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const member = userList[dataIndex];
      
          return (
            <div className="d-flex flex-column">
              {member?.isCharterMember && (
                <div className="d-flex align-items-center gap-2" title="Charter Member">
                  <TbCheck strokeWidth={1.6} className="size-sm text-muted" style={{ visibility: 'hidden' }} />
                  {/* <span className="badge primary-lighter--sbg white--clr fw-400 fit-content d-flex align-items-center gap-2 mb-1 lh-normal">
                    Charter Member
                  </span> */}

                  <span className="size-xxs secondary-animated-gradient--clr fw-700 fit-content d-flex align-items-center text-uppercase" style={{ letterSpacing: '-0.4px' }}>
                    Charter Member
                  </span>
                </div>
              )}

              <span className="d-flex align-items-center gap-2" title="Membership Tier">
                <TbUserShield strokeWidth={1.6} className="size-sm text-muted" />
                {member?.membershipTier}
              </span>

              {member?.membershipTier !== 'Registered User' && (
                <span className="d-flex align-items-center gap-2" title="Membership Creation">
                  <TbCalendarUser strokeWidth={1.6} className="size-sm text-muted" />
                  Starts:
                  {' '}
                  {moment(member?.membershipDateCreated).format('MMM D, YYYY')}
                </span>
              )}

              {member?.membershipTier !== 'Registered User' && (
                <span className="d-flex align-items-center gap-2" title="Membership Expiration">
                  <TbClockExclamation strokeWidth={1.6} className="size-sm text-muted" />
                  {(() => {
                    const daysRemaining = moment(member?.membershipDateExpiration).diff(moment(), 'days');
                    if (moment().isAfter(member?.membershipDateExpiration)) {
                      return (
                        <span>
                          Ends:
                          <span className="text-danger">Expired</span>
                        </span>
                      );
                    } if (daysRemaining <= 30) {
                      return (
                        <span>
                          Ends:
                          {' '}
                          <span className="text-warning">
                            {daysRemaining}
                            {' '}
                            days
                          </span>
                        </span>
                      );
                    }
                    return (
                      <span>
                        Ends:
                        {' '}
                        <span className="text-success">
                          {daysRemaining}
                          {' '}
                          days
                        </span>
                      </span>
                    );
                  })()}
                </span>
              )}

            </div>
          );
        }
      }
    },
    {
      name: 'isActive',
      label: 'Active?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = dataTableData[dataIndex];
          return (
            isActive ? <TbCheck size={26} strokeWidth={2} className="success--clr" /> : <TbX size={26} strokeWidth={2} className="danger--clr" />
          );
        }
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Memberships"
                headerSize="lg"
                primaryButtonBackgroundColor="primary--bg"
                primaryButtonText={auth.isAdmin ? 'Add New Member' : ''}
                primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setSelectedMember(membershipModel);
                  setMemberModalVisible(true);
                }}
              />

              <ButtonGroup className="my-2 flex-wrap">
                <BSButton
                  variant=""
                  className={`size-xxs ${selectedFilter === 'All' ? 'primary--bg--gradient white--clr' : 'primary--clr fw-500'}`}
                  onClick={() => handleFilterChange('All')}
                  style={{
                    boxShadow: 'none',
                    ...(selectedFilter !== 'All' && { border: '1px solid var(--primary-color)' })
                  }}
                >
                  All
                </BSButton>

                <BSButton
                  variant=""
                  className={`size-xxs ${selectedFilter === 'Registered User' ? 'primary--bg--gradient white--clr' : 'primary--clr fw-500'}`}
                  onClick={() => handleFilterChange('Registered User')}
                  style={{
                    boxShadow: 'none',
                    ...(selectedFilter !== 'Registered User' && { border: '1px solid var(--primary-color)' })
                  }}
                >
                  Registered Users
                </BSButton>

                <BSButton
                  variant=""
                  className={`size-xxs ${selectedFilter === 'Engaged Subscriber' ? 'primary--bg--gradient white--clr' : 'primary--clr fw-500'}`}
                  onClick={() => handleFilterChange('Engaged Subscriber')}
                  style={{
                    boxShadow: 'none',
                    ...(selectedFilter !== 'Engaged Subscriber' && { border: '1px solid var(--primary-color)' })
                  }}
                >
                  Subscribers
                </BSButton>

                <BSButton
                  variant=""
                  className={`size-xxs ${selectedFilter === 'Engaged Member' ? 'primary--bg--gradient white--clr' : 'primary--clr fw-500'}`}
                  onClick={() => handleFilterChange('Engaged Member')}
                  style={{
                    boxShadow: 'none',
                    ...(selectedFilter !== 'Engaged Member' && { border: '1px solid var(--primary-color)' })
                  }}
                >
                  Members
                </BSButton>

                <BSButton
                  variant=""
                  className={`size-xxs ${selectedFilter === 'Engaged Sponsor' ? 'primary--bg--gradient white--clr' : 'primary--clr fw-500'}`}
                  onClick={() => handleFilterChange('Engaged Sponsor')}
                  style={{
                    boxShadow: 'none',
                    ...(selectedFilter !== 'Engaged Sponsor' && { border: '1px solid var(--primary-color)' })
                  }}
                >
                  Sponsors
                </BSButton>
              </ButtonGroup>

              <CustomBlock>
                {dataTableData.length > 0 && (
                  <MUIDataTable
                    data={userList}
                    columns={columns}
                    options={{
                      filter: true,
                      sort: true,
                      print: false,
                      download: true,
                      pagination: true,
                      rowsPerPage: 100,
                      rowsPerPageOptions: [10, 25, 50, 100, 1000],
                      selectableRows: 'none',
                      search: true,
                      jumpToPage: true,
                      textLabels: { pagination: { jumpToPage: 'Page No:' } },
                      downloadOptions: {
                        filename: `${selectedFilter} List ${moment().format('MM-DD-YYYY')}.csv`,
                        separator: ',',
                      },
                      onDownload: (buildHead, buildBody, columns, data) => {
                        const headers = ['First Name', 'Last Name', 'Email', 'Company'];
                      
                        const downloadData = userList.map((member) => [
                          member.firstName || '',
                          member.lastName || '',
                          member.emailAddress || '',
                          member.membershipTier || '',
                          `"${member.companyName || ''}"`
                        ]);
                      
                        let csvContent = `${headers.join(',')}\n`;
                      
                        csvContent += downloadData.map((row) => row.join(',')).join('\n');
                      
                        return csvContent;
                      }
                    }}
                  />
                )}
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* MEMBER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={memberModalVisible}
        size="lg"
        contentHeader={selectedMember?.id ? 'Edit Member' : 'Add New Member'}
        primaryModalActionText={selectedMember?.id ? 'Update' : 'Add'}
        primaryModalActionColor="success--bg"
        primaryModalActionOnClick={memberFormik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setMemberModalVisible(false);
          memberFormik.resetForm();
        }}
      >
        <FormBlock className="" onSubmit={memberFormik.handleSubmit}>
          <Section hasNoContainer>
            {/* IS ACTIVE | IS CHARTER MEMBER */}
            <>
              <ContentBlock cols={12} className="mt-10">
                <CheckboxBlock
                  label="Is Active?"
                  id="isActive"
                  {...memberFormik.getFieldProps('isActive')}
                  isChecked={memberFormik.values.isActive}
                  labelClassName="dark--clr"
                />
              </ContentBlock>

              <ContentBlock cols={12} className="mt-10">
                <CheckboxBlock
                  label="Is Charter Member?"
                  id="isCharterMember"
                  {...memberFormik.getFieldProps('isCharterMember')}
                  isChecked={memberFormik.values.isCharterMember}
                  labelClassName="ml-0 dark--clr"
                />
              </ContentBlock>

              <ContentBlock cols={12} className="mt-10">
                <CheckboxBlock
                  label="Is Eligible for Membership?"
                  id="isEligible"
                  {...memberFormik.getFieldProps('isEligible')}
                  isChecked={memberFormik.values.isEligible}
                  labelClassName="dark--clr"
                />
              </ContentBlock>
            </>
      
            {/* CONTACT DETAILS */}
            <>
              <ContentBlock cols={12} className="mt-30 mb-10">
                <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Contact Details</h3>
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="First Name"
                  placeholder="John"
                  errorMessage={memberFormik.touched.firstName && memberFormik.errors.firstName}
                  inputState={`${helper.getInputClasses(memberFormik, 'firstName')}`}
                  {...memberFormik.getFieldProps('firstName')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="Last Name"
                  placeholder="Smith"
                  errorMessage={memberFormik.touched.lastName && memberFormik.errors.lastName}
                  inputState={`${helper.getInputClasses(memberFormik, 'lastName')}`}
                  {...memberFormik.getFieldProps('lastName')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Phone Number"
                  placeholder="(555) 555-5555"
                  mask="(999) 999-9999"
                  errorMessage={memberFormik.touched.phoneNumber && memberFormik.errors.phoneNumber}
                  inputState={`${helper.getInputClasses(memberFormik, 'phoneNumber')}`}
                  {...memberFormik.getFieldProps('phoneNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="Email Address"
                  placeholder="address@email.com"
                  errorMessage={memberFormik.touched.emailAddress && memberFormik.errors.emailAddress}
                  inputState={`${helper.getInputClasses(memberFormik, 'emailAddress')}`}
                  className={`input-block ${selectedMember?.id ? 'disabled' : ''}`}
                  {...memberFormik.getFieldProps('emailAddress')}
                  isDisabled={!!selectedMember?.id}
                />
              </ContentBlock>

            </>

            {/* ACCOUNT DETAILS */}
            {!selectedMember?.id && (
              <>
                <ContentBlock cols={12} className="mt-30 my-10">
                  <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Account Details</h3>
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    isRequired
                    label="Password"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Enter password"
                    inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
                    inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
                    errorMessage={memberFormik.touched.password && memberFormik.errors.password}
                    inputState={`${helper.getInputClasses(memberFormik, 'password')}`}
                    {...memberFormik.getFieldProps('password')}
                  />
                </ContentBlock>
              </>
            )}

            {/* ADDRESS INFORMATION */}
            <>
              <ContentBlock cols={12} className="mt-30 my-10">
                <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Address Information</h3>
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Company Name"
                  placeholder="Smith & Co."
                  errorMessage={memberFormik.touched.companyName && memberFormik.errors.companyName}
                  inputState={`${helper.getInputClasses(memberFormik, 'companyName')}`}
                  {...memberFormik.getFieldProps('companyName')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="Street Address"
                  placeholder="123 Main St"
                  errorMessage={memberFormik.touched.addressLine1 && memberFormik.errors.addressLine1}
                  inputState={`${helper.getInputClasses(memberFormik, 'addressLine1')}`}
                  {...memberFormik.getFieldProps('addressLine1')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Apt/Suite/Unit"
                  placeholder="Apt 1"
                  errorMessage={memberFormik.touched.addressLine2 && memberFormik.errors.addressLine2}
                  inputState={`${helper.getInputClasses(memberFormik, 'addressLine2')}`}
                  {...memberFormik.getFieldProps('addressLine2')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="City"
                  placeholder="Baton Rouge"
                  errorMessage={memberFormik.touched.city && memberFormik.errors.city}
                  inputState={`${helper.getInputClasses(memberFormik, 'city')}`}
                  {...memberFormik.getFieldProps('city')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="State"
                  placeholder="LA"
                  errorMessage={memberFormik.touched.state && memberFormik.errors.state}
                  inputState={`${helper.getInputClasses(memberFormik, 'state')}`}
                  {...memberFormik.getFieldProps('state')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  isRequired
                  label="ZIP/Postcode"
                  placeholder="12345"
                  errorMessage={memberFormik.touched.zipCode && memberFormik.errors.zipCode}
                  inputState={`${helper.getInputClasses(memberFormik, 'zipCode')}`}
                  {...memberFormik.getFieldProps('zipCode')}
                />
              </ContentBlock>
            </>

            {/* MEMBERSHIP DETAILS */}
            <>
              <ContentBlock cols={12} className="mt-30 my-10">
                <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Membership Details</h3>
              </ContentBlock>

              <ContentBlock cols={6}>
                <SelectBlock
                  isRequired
                  label="Membership Tier"
                  labelColor="dark--clr"
                  isClearable
                  value={helper.getMembershipOptions().find((option) => option.label === memberFormik.values.membershipTier) || null}
                  onChange={(option) => memberFormik.setFieldValue('membershipTier', option ? option.label : '')}
                  options={helper.getMembershipOptions()}
                  inputState={`${helper.getInputClasses(memberFormik, 'membershipTier')}`}
                />
              </ContentBlock>

              {memberFormik.values.membershipTier && (
              <ContentBlock cols={6}>
                <InputBlock
                  type="date"
                  label="Membership Start Date"
                  placeholder="e.g. MM/DD/YYYY"
                  value={memberFormik.values.membershipDateCreated ? moment(memberFormik.values.membershipDateCreated).format('YYYY-MM-DD') : ''}
                  onChange={(selectedDates) => {
                    if (selectedDates && selectedDates.length > 0) {
                      const selectedDate = moment(selectedDates[0]).format('YYYY-MM-DD');
                      memberFormik.setFieldValue('membershipDateCreated', selectedDate);
                    }
                  }}
                  errorMessage={memberFormik.errors.membershipDateCreated && memberFormik.touched.membershipDateCreated ? memberFormik.errors.membershipDateCreated : null}
                  inputState={`${helper.getInputClasses(memberFormik, 'membershipDateCreated')}`}
                />
              </ContentBlock>
              )}
            </>

            {/* NOTES */}
            <>
              <ContentBlock cols={12} className="mt-30 my-10">
                <h3 className="size-sm fw-700 primary-lighter--clr p-0 subheader">Additional Information</h3>
              </ContentBlock>
              
              <ContentBlock cols={12}>
                <TextAreaBlock
                  label="Notes"
                  placeholder="Enter additional notes"
                  value={memberFormik.values.notes || ''}
                  errorMessage={memberFormik.touched.notes && memberFormik.errors.notes}
                  inputState={`${helper.getInputClasses(memberFormik, 'notes')}`}
                  {...memberFormik.getFieldProps('notes')}
                  labelColor="dark--clr"
                />
              </ContentBlock>

              <>
                <ContentBlock cols={12} className="mb-10">
                  <span className="static-label dark--clr fw-600">Profile Image</span>
                </ContentBlock>
                
                {selectedMember?.imageFilePath ? (
                  <ContentBlock cols={6} className="d-flex align-items-center justify-content-center">
                    {/* <img src={defaultProfileImage} alt="" style={{ height: '100%', maxHeight: '74px', margin: '0 auto' }} /> */}

                    <CustomBlock
                      className="image"
                      style={{
                        background: `url(${process.env.REACT_APP_API_URL}Attachments${selectedMember.imageFilePath})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100px',
                        height: '100px',
                        borderRadius: '300px',
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                      }}
                    />
                  </ContentBlock>
                ) : (
                  <ContentBlock cols={6} className="d-flex align-items-center justify-content-center">
                    <CustomBlock
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '300px',
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                      }}
                    >
                      <TbPhotoCirclePlus strokeWidth={1.5} size={45} className="light-grey--clr" style={{ height: '100%', maxHeight: '74px', margin: '0 auto' }} />
                    </CustomBlock>
                  </ContentBlock>
                )}

                <ContentBlock cols={6}>
                  <FileUploadBlock
                    onupdatefiles={setUploadedImage}
                    ref={filePondRef}
                    acceptedFileTypes={['image/*']}
                    labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
                  />
                </ContentBlock>
              </>
            </>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* RESET PASSWORD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={updatePasswordModalVisible}
        size="lg"
        contentHeader="Reset User Password"
        primaryModalActionText="Reset"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={update_password_user_formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setUpdatePasswordRegisterModalVisible(false);
        }}
      >
        <FormBlock onSubmit={update_password_user_formik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="New Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsResetPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={update_password_user_formik.errors.password}
                inputState={`${helper.getInputClasses(update_password_user_formik, 'password')}`}
                {...update_password_user_formik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm New Password"
                placeholder="Confirm new password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmResetPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={update_password_user_formik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(update_password_user_formik, 'confirmPassword')}`}
                {...update_password_user_formik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions, ...confirmModal.actions })(MemberListingPage);