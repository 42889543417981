/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
// ASSETS
import AnimatedLogo from '../../../../../components/AnimatedLogo';

const WebsiteIntroSection = (props) => {
  const { history } = props;
  
  return (
    <CustomBlock className="content-section">
      <CustomBlock className="d-flex flex-column align-items-center mx-auto gap-40">
        <AnimatedLogo
          // onClick
          onTimer="6s"
          width="350px"
          center
        />
        
        <Fade>
          <Button
            text="Your Referral Future"
            className="size-sm secondary-gradient--bg circle"
            size="sm"
            onClick={() => history.push('/contact-geaux-engage')}
            style={{ background: 'linear-gradient(45deg, #FF3C00 40%, #FB8500 100%)' }}
          />
        </Fade>
      </CustomBlock>
    </CustomBlock>
  );
};

export default WebsiteIntroSection;