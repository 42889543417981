/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../../components/CustomBlock';
import Overlay from '../../../../components/Overlay';
import Banner from '../../../../components/Banner';
import AnimatedLogo from '../../../../components/AnimatedLogo';
import InfiniteScroller from '../../../../components/InfiniteScroller';
import TextHeader from '../../../../components/TextHeader';
import LouisianaMap from '../../../../components/LouisianaMap';
import Button from '../../../../components/Button';
import CustomBannerInsert from './CustomBannerInsert';
// SECTIONS
import WebsiteIntroSection from './sections/WebsiteIntroSection';
import MembersGrowthSection from './sections/MembersGrowthSection';
import BusinessGrowthSection from './sections/BusinessGrowthSection';
import LouisianaMapSection from './sections/LouisianaMapSection';
// ASSETS
// import HomepageCustomBannerImage from '../../../../assets/img/homepage-banner-image_default.png';
import GrowWithOurMembersImage from '../../../../assets/img/content-image_grow-with-us.png';
import BusinessGrowthBackgroundTexture from '../../../../assets/img/background-texture_business-growth.png';
// HELPERS
import constants from '../../../../constants/constants';
// HELPERS AND SERVICES
import * as memberService from '../../../../services/management/memberService';

const HomePage = (props) => {
  const { dynamicPageModel, buttonAction, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [offset, setOffset] = useState(0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => setOffset(window.pageYOffset);

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      {/* BANNER SECTION */}
      <CustomBlock className="banner-container">
        <Slider {...bannerSliderSettings}>
          {/* CUSTOM BANNER INSERT */}
          <CustomBannerInsert
            history={history}
            // setOffset={setOffset}
          />

          {/* DYNAMIC BANNER */}
          {banners.map((banner) => (
            <Banner
              // isDynamicBanner
              isTitleUppercase
              isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
              isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
              title={banner.title}
              imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
              description={banner.description}
              primaryActionText={banner.primaryButtonText}
              primaryActionOnClick={() => {
                buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
              }}
              secondaryActionText={banner.secondaryButtonText}
              secondaryActionOnClick={() => {
                buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
              }}
            />
          ))}
        </Slider>
      </CustomBlock>

      <CustomBlock className="main-content">
        {/* WEBSITE INTRO */}
        <WebsiteIntroSection history={history} />

        {/* INTRO TO REFERRAL MEMBERS */}
        <MembersGrowthSection />

        {/* BUSINESS GROWTH */}
        <BusinessGrowthSection />

        {/* LOUISIANA MAP */}
        <LouisianaMapSection />
      </CustomBlock>
    </>
  );
};

export default HomePage;