/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
// COMPONENTS
// ICONS
import { BiRefresh } from 'react-icons/bi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
// ASSETS
import '../assets/custom/AnimatedLogoStyles.css';

const AnimatedLogo = (props) => {
  const {
    width,
    height,
    center,
    onClick,
    onTimer,
  } = props;

  const [isLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(null);
  const [animationKey, setAnimationKey] = useState(0);
  const animationIntervalRef = useRef(null);

  // Function to parse the time string and convert it to milliseconds
  const parseTimeToMillis = (timeStr) => {
    const num = parseFloat(timeStr);
    const unit = timeStr.match(/m?s/)?.[0] || 's';
    return unit === 's' ? num * 1000 : num;
  };

  const triggerAnimation = () => {
    setAnimationKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (onTimer) {
      const delay = parseTimeToMillis(onTimer);

      // Set up an interval for the animation
      animationIntervalRef.current = setInterval(triggerAnimation, delay);

      // Trigger initial animation
      triggerAnimation();
    }

    // Cleanup function to clear the interval
    return () => {
      if (animationIntervalRef.current) {
        clearInterval(animationIntervalRef.current);
      }
    };
  }, [onTimer]);

  const handleLogoAnimation = () => {
    setIsAnimating(true);
    setAnimationKey((prevKey) => prevKey + 1);

    // Clear existing interval
    if (animationIntervalRef.current) {
      clearInterval(animationIntervalRef.current);
    }

    // Set a new interval for the next animation replay
    if (onTimer) {
      const delay = parseTimeToMillis(onTimer);
      animationIntervalRef.current = setInterval(() => {
        setIsAnimating(true);
        setAnimationKey((prevKey) => prevKey + 1);
      }, delay);
    }
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock className={`animated-logo-container ${center && 'animated-logo-container__center'}`}>
        <svg
          width={width || '100%'}
          height={height || 'auto'}
          viewBox="0 0 370 286"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={animationKey}
        >
          {/* eslint-disable react/jsx-max-props-per-line, react/self-closing-comp, react/no-unknown-property */}
          <svg viewBox="0 0 4222 2392" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.7303 203.753L45.7303 203.738V203.723C45.7303 163.872 65.7766 124.624 100.08 94.3025C134.43 63.9392 179.994 45.467 224.551 45.4648C224.553 45.4648 224.556 45.4648 224.558 45.4648L3206.97 46.0518H3206.98C3298.07 46.0518 3380.4 117.19 3380.4 220.137V1001.25C3380.4 1097.34 3308.57 1173.44 3220.34 1173.44H3220.27L3220.21 1173.44L434.962 1181.61L416.75 1181.66L403.701 1194.36L45.392 1543.23C45.2716 1401.06 45.103 1142.96 45.2439 934.436L45.7303 203.753Z" fill="#202020" stroke="#202020" stroke-width="90" class="svg-elem-1"></path>
            <path d="M1011.86 348.44C983.316 315.753 906.539 284.181 835.173 275.781C561.977 243.63 380.257 435.469 443.104 689.682C496.835 907.02 761.787 992.625 1011.99 873.487L1028.2 865.769V540.571H785.408V665.702L897.757 666.493V769.039L887.954 773.936C712.607 861.54 539.781 734.677 578.261 546.604C608.105 400.74 793.497 345.646 911.186 447.664C915.866 451.722 920.023 454.716 920.421 454.318C920.82 453.92 942.121 430.58 967.758 402.453L1014.37 351.313L1011.86 348.44Z" fill="#FAFAFA" class="svg-elem-2"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1349.09 474.248C1457.14 485.593 1519.52 566.588 1522.41 699.293L1523.23 736.616H1216.7V739.474C1216.7 751.498 1231.27 779.604 1244.13 792.376C1289.98 837.937 1364.36 829.495 1407.8 773.799L1411.14 769.511L1417.6 774.667C1421.15 777.504 1441.54 792.569 1462.9 808.146L1501.73 836.467L1495.6 843.797C1380.83 980.87 1132.1 923.997 1091.6 751.425C1054.22 592.109 1180.19 456.512 1349.09 474.248ZM1216.71 644.719C1216.71 612.466 1246.01 579.259 1283.38 569.172C1336.79 554.754 1388.75 588.378 1391.84 639.355L1392.39 648.403L1216.71 649.181V644.719Z" fill="#FAFAFA" class="svg-elem-3"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1831.23 475.826C1888.16 483.078 1932.43 513.347 1953.39 559.359C1972.47 601.218 1975.21 630.523 1975.23 792.809L1975.24 910.077L1855.35 909.288L1854.94 882.521C1854.48 852.628 1854.38 852.417 1846.31 864.141C1781.82 957.773 1598.97 929.832 1569.36 821.822C1536.7 702.711 1632.37 640.078 1849.16 638.635L1855.04 638.596L1854.16 627.016C1848.5 552.161 1735.08 537.329 1670.69 603.024L1662.46 611.416L1628.47 576.537C1609.77 557.355 1594.29 541.219 1594.07 540.679C1593.85 540.14 1598.26 535.959 1603.87 531.388C1660.99 484.835 1743.55 464.657 1831.23 475.826ZM1691.75 783.903C1691.79 747.661 1722.91 727.816 1786.95 723.209C1796.37 722.532 1815.51 721.881 1829.49 721.763L1854.9 721.547L1853.84 772.136L1848.68 782.657C1816.94 847.405 1691.68 848.399 1691.75 783.903Z" fill="#FAFAFA" class="svg-elem-4"></path>
            <path d="M2173.62 738.882L2172.67 483.27H2041.6L2041.6 605.043C2041.61 793.874 2045.58 826.601 2073.25 865.997C2125.98 941.082 2270.43 937.382 2319.07 859.698C2326.47 847.878 2326.5 847.95 2326.96 880.26L2327.37 909.29L2453.29 910.077V483.27H2322.25L2321.23 732.85L2317.28 747.334C2296.2 824.562 2198.27 828.818 2177.6 753.403L2173.62 738.882Z" fill="#FAFAFA" class="svg-elem-5"></path>
            <path d="M2729.12 566.659C2718.83 550.733 2702.56 525.624 2692.96 510.862L2675.51 484.024L2599.08 483.633C2557.05 483.417 2522.66 483.655 2522.66 484.161C2522.66 484.955 2544.87 516.267 2589.8 578.783C2651.77 665.023 2662.92 681.092 2661.91 682.751C2660.7 684.743 2635.97 718.502 2616.76 744.382C2610.21 753.213 2593.99 775.171 2580.72 793.18C2546.47 839.655 2522.57 871.976 2508.06 891.453C2501.16 900.72 2495.51 908.7 2495.51 909.187C2495.51 909.673 2529.58 909.896 2571.22 909.681L2646.94 909.29L2655.92 896.472C2660.86 889.422 2667.73 879.582 2671.18 874.605C2674.64 869.628 2688.24 850.288 2701.4 831.626C2714.57 812.964 2728.9 792.606 2733.25 786.385C2737.61 780.164 2742.78 773.107 2744.75 770.701L2748.33 766.328L2751.93 772.209C2756.02 778.92 2769.92 800.238 2827.86 888.63C2843.86 913.054 2831.16 910.145 2919.78 909.682L2994.93 909.29L2965.13 867.819C2948.73 845.01 2933.58 823.902 2931.44 820.911C2929.31 817.921 2916.87 800.616 2903.81 782.456C2890.74 764.297 2869.2 734.341 2855.93 715.887C2842.66 697.435 2831.8 681.922 2831.8 681.412C2831.8 680.506 2845.44 660.655 2877.52 614.846C2886.38 602.196 2900.86 581.5 2909.69 568.851C2918.52 556.201 2929.32 540.763 2933.68 534.543C2938.04 528.322 2947.81 514.24 2955.39 503.251L2969.18 483.27H2824.58L2819.67 491.187C2816.97 495.542 2811.54 504.194 2807.61 510.414C2803.68 516.635 2793.86 532.243 2785.78 545.099C2752.23 598.479 2751.45 599.613 2749.59 597.561C2748.63 596.493 2739.42 582.587 2729.12 566.659Z" fill="#FAFAFA" class="svg-elem-6"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3940.19 688.854L3659.7 969.485H3458.34L3457.4 982.512C3455.84 1004.41 3453.46 1020.07 3448.97 1037.97C3446.61 1047.38 3444.68 1055.26 3444.68 1055.46C3444.68 1055.67 3501.09 1055.83 3570.05 1055.83H3695.42L3914.9 836.241C4035.61 715.465 4134.89 616.649 4135.51 616.649C4136.14 616.649 4136.46 948.102 4136.23 1353.21L4135.81 2089.78C4136.23 2202.03 4035.73 2304.88 3914.15 2304.88C3914.15 2304.88 3487.13 2306.39 2429.09 2306.06L958.908 2305.61C848.216 2305.61 745.797 2198.15 745.797 2100.2L742.39 1282.87H656.836C656.53 1551.82 654.68 1820.83 657.546 2089.78C657.546 2252.44 804.364 2391.25 967.604 2391.25H3911.41C4065.46 2391.25 4220.22 2265.36 4220.22 2103.18L4221.82 408.223C4221.82 408.223 4094.46 534.507 3940.19 688.854Z" fill="#202020" class="svg-elem-7"></path>
            <path d="M1053.4 1983.33V1495.02H1385.23V1596.25H1162.03V1685.58H1373.33V1785.32H1162.03V1883.56L1397.88 1884.33L1398.66 1983.33H1053.4Z" fill="#202020" class="svg-elem-8"></path>
            <path d="M1455.17 1983.33V1495.02H1602.21C1670.7 1608.21 1739.77 1721.04 1809.32 1833.58V1495.02H1917.95V1983.33L1775.84 1983.29C1705.48 1868.2 1635 1753.17 1564.54 1638.14L1563.78 1983.33H1455.17Z" fill="#202020" class="svg-elem-9"></path>
            <path d="M1978.17 1776.39C1961.17 1617.63 2048.82 1504.41 2204.4 1484.14C2254.85 1477.56 2337.12 1489.25 2379.98 1509.07C2397.34 1517.1 2433.94 1541.79 2434.24 1545.67C2434.32 1546.68 2359.94 1628.12 2358.41 1628.7C2357.77 1628.95 2355 1627.04 2352.25 1624.47C2260.12 1538.35 2108.41 1589.31 2091.16 1712.16C2071.61 1851.46 2196.32 1937.61 2329.57 1876.85L2340.55 1871.84V1794.26H2252.76V1693.02H2444.73L2443.97 1949.83L2429.18 1956.99C2221.28 2057.5 1998.71 1968.38 1978.17 1776.39Z" fill="#202020" class="svg-elem-10"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2474.49 1981.47L2593.11 1983.33L2634.12 1880.6L2821.93 1880.61L2863.08 1983.33H2986.19L2773.5 1495.02H2684.39L2474.49 1981.47ZM2726.79 1642.58L2668.24 1787.56L2782.42 1785.37L2726.79 1642.58Z" fill="#202020" class="svg-elem-11"></path>
            <path d="M2966.84 1717.83C2977.64 1585.13 3056.1 1504.89 3195.43 1484.05C3223.2 1479.9 3276.74 1480.32 3332.24 1495.28C3367.42 1504.77 3426.51 1538.03 3423.26 1546.51C3422.48 1548.53 3349.56 1629.01 3348.51 1629.01C3348.18 1629.01 3342.4 1624.32 3335.66 1618.59C3281.33 1572.41 3188.56 1571.36 3132.78 1616.28C3056.13 1677.99 3062.85 1819.27 3144.94 1871.94C3191.49 1901.81 3266.79 1903.51 3321.54 1875.94L3330.1 1871.63V1794.3L3243.05 1793.51V1693.77H3435L3435.41 1818.82C3435.9 1966.58 3438.25 1948.28 3417.57 1957.91C3181.17 2068 2948.43 1944.03 2966.84 1717.83Z" fill="#202020" class="svg-elem-12"></path>
            <path d="M3504.2 1983.33V1495.02H3836.03V1596.25H3612.82V1685.58H3824.13V1785.3L3613.57 1786.07V1882.84L3849.42 1883.6V1983.33H3504.2Z" fill="#202020" class="svg-elem-13"></path>
          </svg>
          {/* eslint-enable react/jsx-max-props-per-line, react/self-closing-comp, react/no-unknown-property */}
        </svg>

        {onClick && (
          <BiRefresh
            size="25"
            className="refresh-btn"
            onClick={handleLogoAnimation}
          />
        )}
      </CustomBlock>
    </>
  );
};

export default AnimatedLogo;