/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import * as memberService from '../../../../services/management/memberService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const memberTypes = [
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Industrial', label: 'Industrial' },
  { value: 'Dual Sector', label: 'Dual Sector' }
];

const initialMemberModel = {
  id: 0,
  isActive: true,
  name: '',
  description: '',
  type: '',
  primaryLink: '',
  // secondaryLink: '',
  imageFilePath: '',
};

const NetworkManagementPage = (props) => {
  const { history, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { memberId } = useParams();
  const [member, setMember] = useState(initialMemberModel);
  const [selectedMemberType, setSelectedMemberType] = useState([]);
  const [file, setFile] = useState([]);

  const fetchMember = () => {
    setIsLoading(true);
    memberService.getMemberById(memberId).then((res) => {
      setMember(res);
      const memberTypeObj = memberTypes.find((type) => type.value === res.type);
      if (memberTypeObj) {
        setSelectedMemberType(memberTypeObj);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createMember = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an image for the member.', state: 'warning' });
      setIsLoading(false);
      return;
    }

    if (!selectedMemberType.value) {
      showAlert({ text: 'Please select a type for this member.', state: 'warning' });
      setIsLoading(false);
      return;
    }

    model = helper.convertJsonToFormData({
      ...model,
      type: selectedMemberType.value,
      image: file.length > 0 ? file[0].file : null
    });

    setIsLoading(true);
    memberService.createMember(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/networks');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateMember = (model) => {
    if (!selectedMemberType.value) {
      showAlert({ text: 'Please select a type for this member.', state: 'warning' });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    if (file.length > 0) {
      model = { ...model, type: selectedMemberType.value, image: file[0].file };
    } else {
      model = { ...model, type: selectedMemberType.value, imageUrl: member.imageFilePath };
    }

    memberService.updateMember(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/networks');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (memberId) {
      fetchMember();
    }
  }, []);

  const formik = useFormik({
    initialValues: member,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      name: Yup.string().required('Name is required'),
      description: Yup.string().nullable(),
      primaryLink: Yup.string().nullable(),
      // secondaryLink: Yup.string().nullable(),
      imageFilePath: Yup.string().max(2000),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true);
      if (memberId) {
        updateMember(values);
      } else {
        createMember(values);
      }
    },
  });

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock className="content-container--card-style mb-120" onSubmit={formik.submitForm}>

              {/* MEMBER DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={memberId ? 'Edit Network Member' : 'New Network Member'}
                    headerSize="lg"
                    className="mb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Network Member Details"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr mb-2"
                  />
                </ContentBlock>

                {/* IS MEMBER ACTIVE */}
                <Section isFullWidth className="pl-0 mt-20">
                  <ContentBlock cols={3}>
                    <CheckboxBlock
                      containerClassName="pl-0 mb-30"
                      label="Is Network Member Active?"
                      id="isActive"
                      {...formik.getFieldProps('isActive')}
                      isChecked={formik.values.isActive}
                    />
                  </ContentBlock>
                </Section>

                {/* MEMBER NAME & TYPE */}
                <Section isFullWidth className="pl-0">
                  <ContentBlock cols={3}>
                    <InputBlock
                      isRequired
                      label="Name"
                      placeholder="e.g. Primtek"
                      errorMessage={formik.errors.name}
                      inputState={`${helper.getInputClasses(formik, 'name')}`}
                      {...formik.getFieldProps('name')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <SelectBlock
                      isRequired
                      label="Type"
                      labelColor="dark--clr"
                      options={memberTypes}
                      isClearable
                      errorMessage={formik.errors.type}
                      inputState={`${helper.getInputClasses(formik, 'type')}`}
                      {...formik.getFieldProps('type')}
                      onMenuOpen={() => formik.setFieldTouched('type', true)}
                      value={selectedMemberType}
                      onChange={(opt) => {
                        opt = opt === null ? [] : opt;
                        setSelectedMemberType(opt);
                        formik.setFieldValue('type', opt.value);
                      }}
                    />
                  </ContentBlock>
                </Section>

                {/* MEMBER DESCRIPTION */}
                <Section isFullWidth className="pl-0 pr-0 mt-5">
                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      isRequired
                      label="Description"
                      placeholder="Briefly describe the member's business, focusing on unique services and specialties (around 30-40 words, or 2-3 sentences). Highlight key achievements that showcase expertise and distinction in their field."
                      errorMessage={formik.errors.description}
                      inputState={`${helper.getInputClasses(formik, 'description')}`}
                      {...formik.getFieldProps('description')}
                      labelColor="dark--clr"
                    />
                  </ContentBlock>
                </Section>

                {/* MEMBER PRIMARY LINK */}
                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={6}>
                    <InputBlock
                      label="Website Link"
                      placeholder="e.g. https://primtek.net"
                      errorMessage={formik.errors.primaryLink}
                      inputState={`${helper.getInputClasses(formik, 'primaryLink')}`}
                      {...formik.getFieldProps('primaryLink')}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              {/* MEMBER IMAGE */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30 mb-80"
              >
                <ContentBlock>
                  <ContentHeader
                    title="Member Image"
                    subtitle="Upload an image for this member."
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={6}>
                    {!memberId
                      ? (
                        <ContentBlock className="mt-15">
                          <FileUploadBlock onupdatefiles={setFile} />
                        </ContentBlock>
                      )
                      : (
                        <>
                          <CustomBlock className="flex-center pt-15 pb-30">
                            <Image
                              source={`${process.env.REACT_APP_API_URL}Attachments/${formik.values.imageFilePath}`}
                              className="featuredItem-image pl-10 pr-10"
                            />
                          </CustomBlock>
                          <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                        </>
                      )}
                  </ContentBlock>
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={memberId > 0 ? 'Save Changes' : 'Create Member'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/admin/management/networks"
                optionalActionText={memberId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/admin/management/networks"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
  }
)(NetworkManagementPage);