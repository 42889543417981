/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import zIndex from '@material-ui/core/styles/zIndex';
import CustomBlock from '../../../../../components/CustomBlock';
// ASSETS
import BusinessGrowthBackgroundTexture from '../../../../../assets/img/background-texture_business-growth.png';

const BusinessGrowthSection = () => (
  <CustomBlock className="position-relative w-100">
    <CustomBlock
      className=""
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '0',
        width: '100%',
        height: '100%',
        background: `radial-gradient(circle at center, #ffc0a8 30%, #ffe0c1 100%), url(${BusinessGrowthBackgroundTexture})`,
        backgroundBlendMode: 'hard-light',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    />
    
    <CustomBlock className="content-section align-items-center mx-auto py-80">
      <Fade>

        <CustomBlock className="d-flex flex-column">
          <h3 className="size-xxxl Xprimary--clr secondary-animated-gradient--clr fw-800 mb-20">
            Fueling Business Growth
          </h3>
          {/* <p className="dark--clr size-md mb-20">
            Every business thrives on business growth. Geaux Engage referral marketing partners maintain a steady flow of business in your sales funnel. Referrals and introductions build trust with the prospect and confidence in the sales process.
          </p>
          <p className="dark--clr size-md">
            Building a steady referral business requires trained referral partners who can recognize prospects for you and contact referrals professionally. Geaux Engage allows you to watch referral partner prospects give and receive referrals at meetings. Handing out business cards and being proud to ask for business is expected behavior. Giving referrals is the best way to receive referrals.
          </p> */}

          <Fade cascade>
            {/* eslint-disable */}
            <p className="dark--clr size-md mb-30">
              Every business thrives on <span className="primary-animated-gradient--clr fw-600">meaningful connections that drive growth</span>. With our network, you can <span className="primary-animated-gradient--clr fw-600">transform casual introductions into profitable, long-lasting relationships</span>. Our dedicated community empowers you to grow your business through <span className="primary-animated-gradient--clr fw-600">trusted referrals and genuine support from like-minded professionals</span>.
            </p>

            <p className="dark--clr size-md mb-30">
              Imagine <span className="primary-animated-gradient--clr fw-600">partnering with professionals who are genuinely committed to your success</span>. We open doors to opportunities that not only <span className="primary-animated-gradient--clr fw-600">elevate your brand</span> but also <span className="primary-animated-gradient--clr fw-600">expand your reach effortlessly</span>, giving you a competitive edge in your industry.
            </p>

            <p className="dark--clr size-md">
              <span className="primary-animated-gradient--clr fw-600">Join our community today</span> and experience how <span className="primary-animated-gradient--clr fw-600">strategic networking can propel your business to the next level</span>. Together, we’ll make your business goals a reality.
            </p>
            {/* eslint-enable */}
          </Fade>
        </CustomBlock>
      </Fade>
    </CustomBlock>
  
  </CustomBlock>
);

export default BusinessGrowthSection;