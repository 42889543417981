/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef
} from 'react';
import { connect } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
import moment from 'moment';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// COMPONENTS
import {
  Container, Row, Col, Modal, Form, Image, NavLink, ButtonGroup, Spinner
} from 'react-bootstrap';
import {
  TbBrandLinkedin, TbWorldWww, TbSortAscendingLetters, TbSortDescendingLetters
} from 'react-icons/tb';
import ContentBlock from '../../../../../components/ContentBlock';
import FormBlock from '../../../../../components/FormBlock';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import CustomBlock from '../../../../../components/CustomBlock';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
// ASSETS
import defaultMemberProfileImage from '../../../../../assets/client/default-member-profile-image.png';
// SERVICES AND HELPERS
import * as membershipService from '../../../../../services/management/membershipService';
import * as postService from '../../../../../services/post/postService';
import * as helper from '../../../../../helpers/helper';
import * as alertRedux from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';

const CommunityTab = (props) => {
  const { title, membership, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  // POST FILTERING
  const [posts, setPosts] = useState([]);
  // const [filteredPosts, setFilteredPosts] = useState([]);
  // const [sortOrder, setSortOrder] = useState('asc');
  // const [filter, setFilter] = useState('All');
  // POST MEMBER
  const [selectedMember, setSelectedMember] = useState(null);
  const [showMemberModal, setShowMemberModal] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      const data = await postService.getAllActivePosts();
      const sortedPosts = data.sort((a, b) => new Date(b.DateUpdated) - new Date(a.DateUpdated));
      // console.log('DATA \n', sortedPosts);
      setPosts(sortedPosts);
    } catch (error) {
      // console.error('Error fetching posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleFilterChange = (selectedFilter) => {
  //   setFilter(selectedFilter);
  
  //   let updatedFilteredPosts = posts;
  
  //   if (selectedFilter === 'Most Recent') {
  //     updatedFilteredPosts = posts.filter((post) => post.post.DateUpdated).sort(
  //       (a, b) => new Date(b.post.DateUpdated) - new Date(a.post.DateUpdated)
  //     );
  //   }
  
  //   const sortedFilteredPosts = updatedFilteredPosts.sort((a, b) => (sortOrder === 'asc'
  //     ? a.lastName.localeCompare(b.lastName)
  //     : b.lastName.localeCompare(a.lastName)));
  
  //   setFilteredPosts(sortedFilteredPosts);
  // };

  const handleMemberSelect = async (memberId) => {
    console.log(`handleMemberSelect(${memberId}) \n`);
    // setSelectedMember(member);
    try {
      const memberData = await membershipService.getMembershipById(memberId);
      console.log('memberData \n', memberData);
      setSelectedMember(memberData);
      setShowMemberModal(true);
    } catch (error) {
      console.error('Error fetching member:', error);
    }
  };

  // const toggleSortOrder = () => {
  //   const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
  //   setSortOrder(newOrder);
  
  //   const sortedPosts = filteredPosts.sort((a, b) => (newOrder === 'asc'
  //     ? a.post.Content.localeCompare(b.post.Content)
  //     : b.post.Content.localeCompare(a.post.Content)));
  //   setFilteredPosts(sortedPosts);
  // };

  return (
    <>
      <ContentBlock className="tab-content p-0">
        <CustomBlock className="header primary-lighter--sbg--gradient user-select-none">
          <h4 className="size-xl light-color mx-auto">
            <Fade
              delay={200} cascade
              damping={1e-1}
            >
              {title}
            </Fade>
          </h4>
        </CustomBlock>

        <Container fluid className="">
          {/* <Row className="filters">
            <Col lg={12} className="mb-30">
              <CustomBlock className="d-flex flex-wrap gap-3">
                <ButtonGroup aria-label="Filter Members" className="flex-wrap">
                  <Button
                    variant="none"
                    className={filter === 'All' ? 'primary-lighter--sbg--gradient white--clr' : ''}
                    style={filter !== 'All' ? { border: '1px solid var(--primary-lighter-color)' } : { background: 'var(--primary-lighter-color)' }}
                    onClick={() => handleFilterChange('All')}
                  >
                    All
                  </Button>
                </ButtonGroup>

                <ButtonGroup>
                  <Button
                    variant="none"
                    className={sortOrder === 'asc' ? 'primary-lighter--sbg--gradient active' : 'primary-lighter--sbg--gradient'}
                    onClick={toggleSortOrder}
                  >
                    {sortOrder === 'asc' ? <TbSortAscendingLetters size={24} className="white--clr" /> : <TbSortDescendingLetters size={24} className="white--clr" />}
                  </Button>
                </ButtonGroup>
              </CustomBlock>
            </Col>
          </Row> */}
          
          <Row className="d-flex align-items-stretch">
            <Container fluid className="engage-pages-container mt-4 mb-30">
              {isLoading ? (
                <CustomBlock className="d-flex justify-content-center align-items-center my-5">
                  <Spinner className="primary-lighter--clr" animation="border" role="status" />
                </CustomBlock>
              ) : (
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 1250: 3 }}
                >
                  <Masonry gutter="30px">
                    {posts.map((post) => (
                      <CustomBlock
                        className="post-card p-3 w-100 h-100 d-flex flex-column border rounded bg-white shadow-sm rounder"
                      >
                        <CustomBlock className="d-flex align-items-center justify-content-center gap-3 mb-4">
                          {post.member.imageFilePath ? (
                            <CustomBlock
                              className="member-profile-image"
                              style={{
                                backgroundImage: post.member.imageFilePath
                                  ? `url(${process.env.REACT_APP_API_URL}Attachments${post.member.imageFilePath})`
                                  : `url(${defaultMemberProfileImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: '300px',
                                width: '100px',
                                height: '100px',
                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                              }}
                            />
                          ) : (
                            <CustomBlock
                              className="image"
                              style={{
                                background: `url(${defaultMemberProfileImage})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100px',
                                height: '100px',
                                borderRadius: '300px',
                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                              }}
                            />
                          )}
                          <CustomBlock className="d-flex flex-column align-items-start">
                            <button className="size-md primary-animated-gradient--clr fw-800 lh-normal" onClick={() => handleMemberSelect(post.member.id)}>
                              {`${post.member.firstName} ${post.member.lastName}`}
                            </button>
                            <p className="text-muted lh-1">{post.member.membershipTier}</p>
                          </CustomBlock>
                        </CustomBlock>
                  
                        <p className="post-content size-xs mb-3 dark--clr">
                          {post.content}
                        </p>

                        {(post.link && post.linkLabel) && (
                          <div className="d-flex justify-content-around mt-auto pt-10">
                            <a
                              title="Website"
                              href={post.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-100"
                            >
                              <CustomBlock className="d-flex align-items-center justify-content-center primary--bg--gradient rounded py-2 w-100" style={{ borderRadius: '0 4px 4px 0' }}>
                                <span className="light--clr">{post.linkLabel}</span>
                              </CustomBlock>
                            </a>
                          </div>
                        )}
                      </CustomBlock>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              )}
            </Container>
          </Row>

        </Container>

      </ContentBlock>

      {selectedMember && (
        <Modal show={showMemberModal} onHide={() => setShowMemberModal(false)} centered>
          <Modal.Header closeButton className="border-0 pb-0" />
          <Modal.Body className="pt-0">
            <CustomBlock
              className="member-profile p-3 w-100 h-100 d-flex flex-column rounder bg-white shadow-sm"
            >
              <CustomBlock className="d-flex align-items-center justify-content-center flex-wrap gap-3 mb-4">
                <CustomBlock
                  className="member-profile-image"
                  style={{
                    backgroundImage: `url(${
                      selectedMember.imageFilePath
                        ? `${process.env.REACT_APP_API_URL}Attachments${selectedMember.imageFilePath}`
                        : defaultMemberProfileImage
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '300px',
                    width: '100px',
                    height: '100px',
                  }}
                />
                <CustomBlock className="d-flex flex-column align-items-start">
                  {selectedMember.isCharterMember && (
                  <div
                    className="badge secondary-animated-gradient--bg fit-content d-flex align-items-center justify-content-center mb-2"
                    style={{ marginTop: '1px' }}
                  >
                    <p className="size-xxxxs white--clr fw-500 lh-normal text-uppercase">
                      Charter Member
                    </p>
                  </div>
                  )}
                  <button className="size-md primary-animated-gradient--clr fw-800 lh-normal">
                    {`${selectedMember.firstName} ${selectedMember.lastName}`}
                  </button>
                  <p className="text-muted lh-1">{selectedMember.membershipTier}</p>
                </CustomBlock>
              </CustomBlock>

              {selectedMember.content && (
              <p className="size-xxs mb-3 dark--clr">
                {selectedMember.content}
              </p>
              )}

              <div className="text-muted small mt-auto d-flex flex-column gap-2">
                {selectedMember.companyName && (
                <div className="d-flex align-items-center flex-wrap gy-1 size-xxs lh-normal">
                  <span className="user-select-none">Company: </span>
                  <p className="primary--clr fw-500">{selectedMember.companyName}</p>
                </div>
                )}
                {(selectedMember.city || selectedMember.state) && (
                <div className="d-flex align-items-center flex-wrap gy-1 size-xxs lh-normal">
                  <span className="user-select-none">Location: </span>
                  <p className="primary--clr fw-500">
                    {selectedMember.city}
                    {selectedMember.state && `, ${selectedMember.state}`}
                  </p>
                </div>
                )}
                {selectedMember.emailAddress && (
                <div className="d-flex align-items-center flex-wrap gy-1 size-xxs lh-normal">
                  <span className="user-select-none">Email: </span>
                  <a
                    href={`mailto:${selectedMember.emailAddress}`}
                    className="primary--clr fw-500 text-decoration-none primary-lighter--hover-underline"
                  >
                    {selectedMember.emailAddress}
                  </a>
                </div>
                )}
              </div>

              {(selectedMember.linkedInURL || selectedMember.websiteURL) && (
              <div className="d-flex justify-content-around mt-auto pt-30">
                {selectedMember.linkedInURL && !selectedMember.websiteURL && (
                <a
                  title="Linkedin"
                  href={selectedMember.linkedInURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                >
                  <CustomBlock
                    className="d-flex align-items-center justify-content-center primary--bg--gradient w-100"
                    style={{ borderRadius: '4px' }}
                  >
                    <svg
                      width="70"
                      height="24"
                      viewBox="0 0 70 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="white--clr my-2"
                    >
                      {/* SVG ICON CONTENT */}
                    </svg>
                  </CustomBlock>
                </a>
                )}
                {selectedMember.linkedInURL && selectedMember.websiteURL && (
                <a
                  title="Linkedin"
                  href={selectedMember.linkedInURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                >
                  <CustomBlock
                    className="d-flex align-items-center justify-content-center primary--bg--gradient w-100"
                    style={{ borderRadius: '4px 0 0 4px' }}
                  >
                    <svg
                      width="70"
                      height="24"
                      viewBox="0 0 70 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="white--clr my-2"
                    >
                      {/* SVG ICON CONTENT */}
                    </svg>
                  </CustomBlock>
                </a>
                )}
                {selectedMember.websiteURL && !selectedMember.linkedInURL && (
                <a
                  title="Website"
                  href={selectedMember.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                >
                  <CustomBlock
                    className="d-flex align-items-center justify-content-center primary-lighter--bg--gradient w-100"
                    style={{ borderRadius: '4px' }}
                  >
                    <TbWorldWww size={24} strokeWidth={1.8} className="white--clr my-2" />
                  </CustomBlock>
                </a>
                )}
                {selectedMember.websiteURL && selectedMember.linkedInURL && (
                <a
                  title="Website"
                  href={selectedMember.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100"
                >
                  <CustomBlock
                    className="d-flex align-items-center justify-content-center primary-lighter--bg--gradient w-100"
                    style={{ borderRadius: '0 4px 4px 0' }}
                  >
                    <TbWorldWww size={24} strokeWidth={1.8} className="white--clr my-2" />
                  </CustomBlock>
                </a>
                )}
              </div>
              )}
            </CustomBlock>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertRedux.actions
})(CommunityTab);