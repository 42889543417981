/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, {
  useState, useEffect, useRef
} from 'react';
import moment from 'moment';
import axios from 'axios';
// ICONS
import { Link, useLocation } from 'react-router-dom';
import { IoFilter } from 'react-icons/io5';
// COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Button from '../../../components/Button';
import Banner from '../../../components/Banner';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES
import * as eventService from '../../../services/management/eventService';
import * as eventbriteService from '../../../services/management/eventbriteService';

const EventCountdown = ({ event }) => {
  const countdownRef = useRef();

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment();
      const eventStart = moment(event.start);
      const duration = moment.duration(eventStart.diff(now));
    
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
    
      let result = '';
      if (days > 0) {
        result += `${days} days `;
      }
      if (hours > 0) {
        result += `${hours} hours`;
      }
    
      if (!result) {
        result = `${minutes} minutes`;
      }
    
      countdownRef.current.innerText = result.trim();
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, [event.start]);

  return (
    <span className="white--clr lh-normal" ref={countdownRef}></span>
  );
};

const EventsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [selectedEventOption, setSelectedEventOption] = useState({ label: 'Upcoming Events', value: 'upcoming' });
  const [eventBriteData, setEventBriteData] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [eventbriteData, setEventbriteData] = useState([]);

  const eventOptions = [
    { label: 'All Events', value: 'all' },
    { label: 'Upcoming Events', value: 'upcoming' },
    { label: 'Past Events', value: 'past' },
  ];

  useEffect(() => {
    if (!eventbriteData.length > 0) {
      getEvents();
    }
  }, []);

  const getEvents = async () => {
    setIsLoading(true);
    try {
      eventbriteService.getEventsByStatus('live').then((res) => {
        console.log('RES \n', res);
        setEventbriteData(res);
      });
    } catch (ex) {
      // console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedEventOption = (selectedOption) => {
    setSelectedEventOption(selectedOption);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase title="Events"
        isDynamicBanner imageSource={BannerImage}
      />

      <CustomBlock className="main-content">
        {/* INTRO SECTION */}
        <CustomBlock className="content-section">
          <CustomBlock className="d-flex flex-column">
            <p className="size-xl fw-800 primary--clr mb-10">Opportunities to Geaux Engage</p>
            <p className="size-md mid-grey--clr">Networking and community events information links supporting our referral marketing members.</p>

            {/* EVENTS FILTER */}
            {/* <CustomBlock className="page-actions-container flex-start-col w-100 mt-60">
              <CustomBlock className="filters-header flex-start gap-2 mb-10">
                <IoFilter size="24" className="primary--clr mr-5" />
                <p className="size-mdlg fw-600 primary--clr">Filter Events</p>
              </CustomBlock>
              <CustomBlock className="page-actions d-flex flex-wrap">
                <Button
                  className={`action mid-grey--clr size-xxs fw-400 ${selectedFilter === 'all' ? 'selected' : ''}`}
                  text="All Events"
                  onClick={() => handleFilterChange('all')}
                />
                <Button
                  className={`action mid-grey--clr size-xxs fw-400 ${selectedFilter === 'previous' ? 'selected' : ''}`}
                  text="Previous"
                  onClick={() => handleFilterChange('previous')}
                />
                <Button
                  className={`action mid-grey--clr size-xxs fw-400 ${selectedFilter === 'upcoming' ? 'selected' : ''}`}
                  text="Upcoming"
                  onClick={() => handleFilterChange('upcoming')}
                />
              </CustomBlock>
            </CustomBlock> */}
          </CustomBlock>
        </CustomBlock>

        {/* EVENT LISTING */}
        <Container fluid className="my-4" style={{ width: 'min(90%, 1500px)' }}>
          {/* <Row className="d-flex align-items-stretch"> */}
          {/* <Container fluid className="mt-4 mb-30 p-0"> */}
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1250: 3 }}>
            <Masonry gutter="20px">
              {eventbriteData.map((event) => (
                <CustomBlock key={event.id} className="event-card overflow-hidden rounder white--bg" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                  <CustomBlock
                    style={{
                      backgroundImage: `url(${event.imageUrl})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: '250px',
                    }}
                  />
                  <CustomBlock className="d-flex flex-column gap-3 p-4 h-100">
                    <h5 className="size-mdsm fw-700 primary--clr">{event.title}</h5>
                    <CustomBlock className="d-flex flex-column align-items-start gap-1">
                      <span className="badge success--sbg--gradient fw-500 text-uppercase d-flex gap-2 text-white lh-normal px-2">
                        {event.status}
                        <EventCountdown event={event} />
                      </span>
                      <p className="d-flex gap-2 size-xxxs mid-grey--clr">
                        <span>{moment(event.start).format('MMM Do')}</span>
                        <span>{moment(event.start).format('h:mmA')}</span>
                        <span>-</span>
                        <span>{moment(event.end).format('h:mmA')}</span>
                      </p>
                      <p className="size-xxxs mid-grey--clr">
                        {event.address?.city}
                        {event.address?.city && ', '}
                        {event.address?.state}
                      </p>
                      <p className="size-xs mid-grey--clr mt-15">{event.description}</p>
                    </CustomBlock>
                    <Button
                      text="View Event"
                      className="light--clr primary-lighter--bg--gradient mt-30"
                      onClick={() => window.open(event.link, '_blank')}
                    />
                  </CustomBlock>
                </CustomBlock>
              ))}
            </Masonry>
          </ResponsiveMasonry>
          {/* </Container> */}
          {/* </Row> */}
        </Container>
      </CustomBlock>
    </>
  );
};

export default EventsPage;